<template>
	<div class="nfz1NBFeLVm6IIOo2CLj abensi">
		<div class="hrp2W0A6qvg1zg4ysky3 boxShadow theme-box-shadow-h5">
			<section class="MZG2AG46nbxCf7Pj94bu">
				<div class="EuylP4i2GhrgzkTAbaf0"><span><img src="./../../assets/icons/images/img_tpdl.webp"
							class="goDRiiBsuEuXD3W1NphN" style="width: 0.8rem;"></span><span>
						<svg-icon style="width: .16rem;height: .16rem;" className="XGEOwGXgDL9a2SebPqvj"
							icon-class="comm_icon_wh--svgSprite:all" />
					</span></div>
				<div class="skuC1f1NURrkI5OOZ7Ju">
					<div class="KzvcBdzMHhzwjIUa04tc">
						<div class="FmRERCI89HukhH03x3QV"><label>{{ $t('promote._Coletável')}}</label><span
								class="currencyAmount yellowColor">{{shareInfo.bonus}}</span></div>
						<button disabled="disabled" type="button"
							class="ant-btn ant-btn-success"><span>{{ $t('promote._Receber') }}</span></button><button
							type="button" class="ant-btn ant-btn-primary"
							@click="openUrl('pickUpRecord')"><span>{{ $t('promote._Histórico') }}</span></button>
					</div>
					<div class="tbJe4aWRACpPqK8U19l3">
						<div class="mwkrCf7T9Q6EuRxoGPvN">
							<label>{{ $t('promote._MododeAgente') }}</label><span>{{ $t('promote._Nœud')}}</span>
						</div>
					</div>
				</div>
				<!---->
			</section>
			<!-- {{shareInfo.open_tips}} -->
			<div
				class="ant-spin-nested-loading URYVffVETVSIkPGB9P5E custom-loading__wrap ye40pj_izwzKAVwAomfq QBeJPwd_uejjKLAsjWcb">
				<div class="ant-spin-container">
					<div class="HuBHjdDhrs3shwX2I3cf">
						<div class="Cp3sCgsE5FnXQBFpShix">
							<div class="ant-spin-nested-loading URYVffVETVSIkPGB9P5E custom-loading__wrap">
								<div class="ant-spin-container">
									<div class="_197iSp6iuweVeX9jjWqU">
										<canvas ref="qrcode_Canvas"></canvas>
										<!-- <img src="" style="display: inline-block;"> -->
										<div class="ant-btn ant-btn-link">
											{{ $t('promote._CliqueparaSalvar') }}
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="X7q4mjP_LFhFIJRXfYuM">
							<div class="qiuoPrJlJq7AoDdC_Jp1">
								<!-- 

								-->
								<div class="uQRA1q9mzX1o2pAJ5uZA"><span>{{ $t('promote._MeuLink') }}</span></div>
								<div class="Z1AgDMke12GSrmYdP6eE" @click="copyContent(`${origin}/?id=${userInfo.id}&type=2`)">
									<div tabindex="0" class="ant-select ant-select-enabled"
										@click="copyContent(`${origin}/?id=${userInfo.id}&type=2`)">
										<div role="combobox" aria-autocomplete="list" aria-haspopup="true"
											aria-controls="d2672658-ada8-465f-e0a4-c7a27a9c1756"
											class="ant-select-selection ant-select-selection--single">
											<div class="ant-select-selection__rendered">
												<div class="ant-select-selection-selected-value"
													style="display: block; opacity: 1;">
													{{origin}}/?id={{userInfo.id}}&type=2
												</div>
												<!-- ?id=622024614&type=2 -->
											</div>
											<!-- <span unselectable="on" class="ant-select-arrow"
												style="user-select: none;"><span id="myLink-suffixIcon"
													class="ant-select-arrow-icon">Alterar</span></span> -->
										</div>
									</div>

									<span @click="copyContent(`${origin}/?id=${userInfo.id}&type=2`)" class="PmwtECGo9wfGBMP0uy71 copyIcon COsess8CMJ_BLuGwYYdj">
										<svg-icon className="imageNav-a" style="width: .24rem;height: .24rem;"
											icon-class="comm_icon_copy--svgSprite:all" />
									</span>
								</div>
							</div>
							<div class="qodAUmIjz1fK0NTjDSLf">
								<div><label>{{ $t('promote._Subordinadosdiretos') }} </label><span
										class="dOhCK6tlyL9yiDmj_NGc">{{shareInfo.subordinate}}</span>
								</div>
								<div @click="copyContent(userInfo.id)">
									<label>{{ $t('promote._CódigodeConvite') }}</label><span>{{userInfo.id}}</span><span
										class="PmwtECGo9wfGBMP0uy71 copyIcon COsess8CMJ_BLuGwYYdj">
										<svg-icon style="width: .24rem;height: .24rem;"
											icon-class="comm_icon_copy--svgSprite:all" />
									</span>
								</div>
							</div>
						</div>
						<div class="my-scrollbar-content">
							<div class="cX3I4wr8lagPbzMNAc5i">
								<div><span class="zsbbPf1TFxnlj15CZdFo">
										<svg-icon className="imageWrap" icon-class="icon_tg_share--svgSprite:all" />
									</span><span>Partilhar</span></div>
								<div><img data-groups-id="my-img-d88adec8-da36-445c-afc0-f255a2cec9b9"
										src="./../../assets/kuaijiexiaz/img_tg.png" alt="Telegram" data-blur="0"
										data-status="loaded" class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;"><span>Telegram</span></div>
								<div><img data-groups-id="my-img-41ff331d-8644-4cb8-923d-f5cf0c500175"
										src="./../../assets/kuaijiexiaz/img_facebook.png" alt="Facebook" data-blur="0"
										data-status="loaded" class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;"><span>Facebook</span></div>
								<div><img data-groups-id="my-img-3a08bcec-3ece-4370-9093-503f06ee7259"
										src="./../../assets/kuaijiexiaz/img_wa.png" alt="WhatsApp" data-blur="0"
										data-status="loaded" class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;"><span>WhatsApp</span></div>
								<div><img data-groups-id="my-img-49754333-e841-4188-9d3f-c56ab6a48d62"
										src="./../../assets/kuaijiexiaz/1795519195972894722.png" alt="Instagram"
										data-blur="0" data-status="loaded" class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;"><span>Instagram</span></div>
								<div><img src="./../../assets/kuaijiexiaz/img_line.png" alt="Line" data-blur="0"
										data-status="loaded" class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;"><span>Line</span></div>
								<div><img src="./../../assets/kuaijiexiaz/1795519414047723522.png" alt="Twitter"
										data-blur="0" data-status="loaded" class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;"><span>Twitter</span></div>
								<div><img class="goDRiiBsuEuXD3W1NphN"
										src="./../../assets/kuaijiexiaz/1795519529549303810.png"
										style="width: 0.7rem; height: 0.7rem;"><span>Youtube</span></div>
								<div><img data-groups-id="my-img-ec306707-cc70-4bf5-82a2-6f8e84a64732"
										src="./../../assets/kuaijiexiaz/1795519631237173249.png"
										class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;"><span>Tiktok</span></div>
								<div><img data-groups-id="my-img-5d46d3a5-0368-4aaa-999e-c7f74cf067ce"
										src="./../../assets/kuaijiexiaz/1795519724854632449.png"
										class="goDRiiBsuEuXD3W1NphN"
										style="width: 0.7rem; height: 0.7rem;"><span>Kwai</span></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<section class="QV03qBSNYehJplfhfCcw">
			<div class="Zw36nTThA8kC3264AaHK"><span>Rede do Agente</span>
				<div></div>
			</div>
			<div
				class="ant-spin-nested-loading URYVffVETVSIkPGB9P5E custom-loading__wrap QkbUm1b1I2_PDqWH0vrH boxShadow">
				<div class="ant-spin-container">
					<section class="H_OpJ5CVLNPLrhbyCEGt">
						<div class="VhvyzHpkqTuu7rED66Rc">
							<div class="M0HpRLR01mBt_PBm3WJL theme-box-shadow-h5">
								<div>
									<div class="t0HiyFKEvEAQH2C_LBRk A common-agent-avatar">
										<div data-groups-id="my-img-fca288b3-07c4-4364-b55a-1920ca3d3240"
											data-src-render="https://cdntoos.calor777.im/siteadmin/skin/lobby_asset/common/common/promotion/img_tg_tx_a_tx.webp?manualVersion=1&amp;version=b79ea57941"
											data-blur="0" data-status="loaded" class="goDRiiBsuEuXD3W1NphN"
											style="width: 1rem; height: 1rem; background-position: 0px 0px; background-image: url(&quot;https://cdntoos.calor777.im/siteadmin/skin/lobby_asset/common/common/promotion/img_tg_tx_a_tx.webp?manualVersion=1&amp;version=b79ea57941&quot;); background-size: 100% 100%;">
											<i class="XGEOwGXgDL9a2SebPqvj"
												style="display: inline-flex; justify-content: center; align-items: center;">
												<svg-icon style="width: .16rem;height: .16rem;"
													className="XGEOwGXgDL9a2SebPqvj"
													icon-class="img_txd--svgSprite:all" />
											</i><span class="isBigAgent">A</span>
										</div>
									</div>
								</div>
								<div class="xfLSbGUImFz61wP4W7ta">
									<p><span>Desempenho total 28500</span><span>Comissões Totais <em>225</em></span></p>
									<p><span>D. Direto 5500</span><span>Retorno de Comissão 3% Comissão Direta
											<em>165</em></span></p>
									<p><span>D. dos Outros 3000</span><span>Comissão escalonada 2% outra comissão
											<em>60</em></span></p>
								</div>
							</div>
							<!--  -->
<!-- #img_h5_jt_b1_1--svgSprite:all -->
							<div class="dl2uB2pr2WsrCKrzc80B xXGveCt3o5i1zYQHRp0m"><i
									class="ZuLywNAJTm4ylsXALdMq fnnriUD19roJSo7MqL0g"
									style="display: inline-flex; justify-content: center; align-items: center;">
									<svg-icon icon-class="img_h5_jt_b1_1--svgSprite:all" />
									<!-- <svg
										width="1em" height="1em" fill="currentColor" aria-hidden="true"
										focusable="false">
										<use xlink:href="#img_h5_jt_b1_1--svgSprite:all"></use>
									</svg> -->
								</i><i class="ZuLywNAJTm4ylsXALdMq PozORZAmuay1ZFPORsx6"
									style="display: inline-flex; justify-content: center; align-items: center;">
									<svg-icon icon-class="img_h5_jt_b2_1--svgSprite:all" />
									<!-- <svg
										width="1em" height="1em" fill="currentColor" aria-hidden="true"
										focusable="false">
										<use xlink:href="#img_h5_jt_b2_1--svgSprite:all"></use>
									</svg> -->
								</i><i class="ZuLywNAJTm4ylsXALdMq DOVAU9KBCWgSF17fo9XY"
									style="display: inline-flex; justify-content: center; align-items: center;">
									<svg-icon icon-class="img_h5_jt_b3_1--svgSprite:all" />
									<!-- <svg
										width="1em" height="1em" fill="currentColor" aria-hidden="true"
										focusable="false">
										<use xlink:href="#img_h5_jt_b3_1--svgSprite:all"></use>
									</svg> -->
								</i><span class="_27z2i1Wtl19__pE123a fnnriUD19roJSo7MqL0g">B1 contribui
									comissão <em>15</em></span><span
									class="_27z2i1Wtl19__pE123a PozORZAmuay1ZFPORsx6">B2 contribui comissão
									<em>90</em></span><span class="_27z2i1Wtl19__pE123a DOVAU9KBCWgSF17fo9XY">B3
									contribui comissão <em>60</em></span><i
									class="HI77FtRdvO4aiGOC1sgp vj3waVbdcpVsr3CVecHi"
									style="display: inline-flex; justify-content: center; align-items: center;">
									<svg-icon icon-class="img_h5_jt_c1_1--svgSprite:all" />
									<!-- <svg
										width="1em" height="1em" fill="currentColor" aria-hidden="true"
										focusable="false">
										<use xlink:href="#img_h5_jt_c1_1--svgSprite:all"></use>
									</svg> -->
								</i><span class="_27z2i1Wtl19__pE123a vj3waVbdcpVsr3CVecHi">C1 Contribui para
									A <em>20</em><br> De diferenciais 2%</span><i
									class="HI77FtRdvO4aiGOC1sgp NpBXLYD8gen1pdTgKgcU"
									style="display: inline-flex; justify-content: center; align-items: center;">
									<svg-icon icon-class="img_h5_jt_c2_2--svgSprite:all" />
									<!-- <svg
										width="1em" height="1em" fill="currentColor" aria-hidden="true"
										focusable="false">
										<use xlink:href="#img_h5_jt_c2_2--svgSprite:all"></use>
									</svg> -->
								</i><span class="_27z2i1Wtl19__pE123a NpBXLYD8gen1pdTgKgcU">C2 contribui para
									A <em>40</em><br> Do nível 2%</span><i
									class="HI77FtRdvO4aiGOC1sgp PfOUnpTsOZxU6WLhsunW"
									style="display: inline-flex; justify-content: center; align-items: center;">
									<svg-icon icon-class="img_h5_jt_c3_2--svgSprite:all" />
									<!-- <svg
										width="1em" height="1em" fill="currentColor" aria-hidden="true"
										focusable="false">
										<use xlink:href="#img_h5_jt_c3_2--svgSprite:all"></use>
									</svg> -->
								</i><span class="_27z2i1Wtl19__pE123a PfOUnpTsOZxU6WLhsunW">C3 Contribui para
									A <em>0</em><br> Sem diferença de nível</span></div>


							<!--  -->

							<div class="RmHZ3oVTU8mQ4XuJWB7_">
								<div class="SSkNTcw8v2f7HdeEfu0Q theme-box-shadow-h5">
									<div class="t0HiyFKEvEAQH2C_LBRk B1 common-agent-avatar">
										<div data-groups-id="my-img-a9fedef0-43a9-4aee-8cb3-350f96246a00"
											data-src-render="https://cdntoos.calor777.im/siteadmin/skin/lobby_asset/common/common/promotion/img_tg_tx_b1_b3_tx.webp?manualVersion=1&amp;version=8724090d4c"
											data-blur="0" data-status="loaded" class="goDRiiBsuEuXD3W1NphN"
											style="width: 0.9rem; height: 0.9rem; background-position: 0px 0px; background-image: url(&quot;https://cdntoos.calor777.im/siteadmin/skin/lobby_asset/common/common/promotion/img_tg_tx_b1_b3_tx.webp?manualVersion=1&amp;version=8724090d4c&quot;); background-size: 100% 100%;">
											<i class="ZuLywNAJTm4ylsXALdMq"
												style="display: inline-flex; justify-content: center; align-items: center;">
												<svg-icon icon-class="img_txx--svgSprite:all" />
												<!-- <svg
													width="1em" height="1em" fill="currentColor" aria-hidden="true"
													focusable="false">
													<use xlink:href="#img_txx--svgSprite:all"></use>
												</svg> -->
											</i><span class="">B1</span>
										</div>
									</div>
									<div class="agent-content">
										<p>Apostas Válidas 500</p>
									</div>
								</div>
								<div class="SSkNTcw8v2f7HdeEfu0Q theme-box-shadow-h5">
									<div class="t0HiyFKEvEAQH2C_LBRk B2 common-agent-avatar">
										<div data-groups-id="my-img-bb0857bf-1d4f-44fc-a296-aee34dd1fcb6"
											data-src-render="https://cdntoos.calor777.im/siteadmin/skin/lobby_asset/common/common/promotion/img_tg_tx_b2_tx.webp?manualVersion=1&amp;version=a84340b78f"
											data-blur="0" data-status="loaded" class="goDRiiBsuEuXD3W1NphN"
											style="width: 0.9rem; height: 0.9rem; background-position: 0px 0px; background-image: url(&quot;https://cdntoos.calor777.im/siteadmin/skin/lobby_asset/common/common/promotion/img_tg_tx_b2_tx.webp?manualVersion=1&amp;version=a84340b78f&quot;); background-size: 100% 100%;">
											<i class="ZuLywNAJTm4ylsXALdMq"
												style="display: inline-flex; justify-content: center; align-items: center;">
												<svg-icon icon-class="img_txx--svgSprite:all" />
												<!-- <svg
													width="1em" height="1em" fill="currentColor" aria-hidden="true"
													focusable="false">
													<use xlink:href="#img_txx--svgSprite:all"></use>
												</svg> -->

											</i><span class="">B2</span>
										</div>
									</div>
									<div class="agent-content">
										<p>Apostas Válidas 3000</p>
									</div>
								</div>
								<div class="SSkNTcw8v2f7HdeEfu0Q theme-box-shadow-h5">
									<div class="t0HiyFKEvEAQH2C_LBRk B3 common-agent-avatar">
										<div data-groups-id="my-img-fc0c92f9-f1d9-4c1c-bb55-32b69a29c150"
											data-src-render="https://cdntoos.calor777.im/siteadmin/skin/lobby_asset/common/common/promotion/img_tg_tx_b1_b3_tx.webp?manualVersion=1&amp;version=8724090d4c"
											data-blur="0" data-status="loaded" class="goDRiiBsuEuXD3W1NphN"
											style="width: 0.9rem; height: 0.9rem; background-position: 0px 0px; background-image: url(&quot;https://cdntoos.calor777.im/siteadmin/skin/lobby_asset/common/common/promotion/img_tg_tx_b1_b3_tx.webp?manualVersion=1&amp;version=8724090d4c&quot;); background-size: 100% 100%;">
											<i class="ZuLywNAJTm4ylsXALdMq"
												style="display: inline-flex; justify-content: center; align-items: center;">
												<svg-icon icon-class="img_txx--svgSprite:all" />
												<!-- <svg
													width="1em" height="1em" fill="currentColor" aria-hidden="true"
													focusable="false">
													<use xlink:href="#img_txx--svgSprite:all"></use>
												</svg> -->
											</i><span class="">B3</span>
										</div>
									</div>
									<div class="agent-content">
										<p>Apostas Válidas 2000</p>
									</div>
								</div>
							</div>
							<div class="dl2uB2pr2WsrCKrzc80B qpR6IFJOg7xxFdqGUOfJ"><i
									class="HI77FtRdvO4aiGOC1sgp PzbFhwT8k4imTMJond8w"
									style="display: inline-flex; justify-content: center; align-items: center;">
									<svg-icon icon-class="img_h5_jt_c1_2--svgSprite:all" />
									<!-- <svg
										width="1em" height="1em" fill="currentColor" aria-hidden="true"
										focusable="false">
										<use xlink:href="#img_h5_jt_c1_2--svgSprite:all"></use>
									</svg> -->
								</i><i class="HI77FtRdvO4aiGOC1sgp ucqLARuFLFTYHIhsAHED"
									style="display: inline-flex; justify-content: center; align-items: center;">

									<!-- <svg
										width="1em" height="1em" fill="currentColor" aria-hidden="true"
										focusable="false">
										<use xlink:href="#img_h5_jt_c2_1--svgSprite:all"></use>
									</svg> -->
									<svg-icon icon-class="img_h5_jt_c2_1--svgSprite:all" />
								</i><i class="HI77FtRdvO4aiGOC1sgp OshgYWQfhY0IfRJ7Sbc7"
									style="display: inline-flex; justify-content: center; align-items: center;">
									<svg-icon icon-class="img_h5_jt_c3_1--svgSprite:all" />
									<!-- <svg
										width="1em" height="1em" fill="currentColor" aria-hidden="true"
										focusable="false">
										<use xlink:href="#img_h5_jt_c3_1--svgSprite:all"></use>
									</svg> -->
								</i><span class="_27z2i1Wtl19__pE123a PzbFhwT8k4imTMJond8w">C1 Contribui
									Comissão <em>10</em></span><span
									class="_27z2i1Wtl19__pE123a ucqLARuFLFTYHIhsAHED">C2 Contribui Comissão
									<em>20</em></span><span class="_27z2i1Wtl19__pE123a OshgYWQfhY0IfRJ7Sbc7">C3
									Contribui Comissão <em>600</em></span></div>
							<div class="RmHZ3oVTU8mQ4XuJWB7_">
								<div class="SSkNTcw8v2f7HdeEfu0Q theme-box-shadow-h5">
									<div class="t0HiyFKEvEAQH2C_LBRk C1 common-agent-avatar">
										<div data-groups-id="my-img-26b7ab2c-ac08-495f-85af-6d5185ea8a40"
											data-src-render="https://cdntoos.calor777.im/siteadmin/skin/lobby_asset/common/common/promotion/img_tg_tx_c1_c3_tx.webp?manualVersion=1&amp;version=e3e3fd4832"
											data-blur="0" data-status="loaded" class="goDRiiBsuEuXD3W1NphN"
											style="width: 0.9rem; height: 0.9rem; background-position: 0px 0px; background-image: url(&quot;https://cdntoos.calor777.im/siteadmin/skin/lobby_asset/common/common/promotion/img_tg_tx_c1_c3_tx.webp?manualVersion=1&amp;version=e3e3fd4832&quot;); background-size: 100% 100%;">
											<i class="HI77FtRdvO4aiGOC1sgp"
												style="display: inline-flex; justify-content: center; align-items: center;">
												<svg-icon icon-class="img_txx--svgSprite:all" />
												<!-- <svg
													width="1em" height="1em" fill="currentColor" aria-hidden="true"
													focusable="false">
													<use xlink:href="#img_txx--svgSprite:all"></use>
												</svg> -->
												</i><span class="">C1</span></div>
									</div>
									<div class="agent-content">
										<p>Apostas Válidas 1000</p>
									</div>
								</div>
								<div class="SSkNTcw8v2f7HdeEfu0Q theme-box-shadow-h5">
									<div class="t0HiyFKEvEAQH2C_LBRk C2 common-agent-avatar">
										<div data-groups-id="my-img-924f378b-5842-4a68-87df-3928d36219ec"
											data-src-render="https://cdntoos.calor777.im/siteadmin/skin/lobby_asset/common/common/promotion/img_tg_tx_c1_tx.webp?manualVersion=1&amp;version=6d7b918705"
											data-blur="0" data-status="loaded" class="goDRiiBsuEuXD3W1NphN"
											style="width: 0.9rem; height: 0.9rem; background-position: 0px 0px; background-image: url(&quot;https://cdntoos.calor777.im/siteadmin/skin/lobby_asset/common/common/promotion/img_tg_tx_c1_tx.webp?manualVersion=1&amp;version=6d7b918705&quot;); background-size: 100% 100%;">
											<i class="HI77FtRdvO4aiGOC1sgp"
												style="display: inline-flex; justify-content: center; align-items: center;">
												<svg-icon icon-class="img_txx--svgSprite:all" />
												<!-- <svg
													width="1em" height="1em" fill="currentColor" aria-hidden="true"
													focusable="false">
													<use xlink:href="#img_txx--svgSprite:all"></use>
												</svg> -->
												</i><span class="">C2</span></div>
									</div>
									<div class="agent-content">
										<p>Apostas Válidas 2000</p>
									</div>
								</div>
								<div class="SSkNTcw8v2f7HdeEfu0Q theme-box-shadow-h5">
									<div class="t0HiyFKEvEAQH2C_LBRk C3 common-agent-avatar">
										<div data-groups-id="my-img-c10bd78c-d54a-4f2d-865b-576beccce4f9"
											data-src-render="https://cdntoos.calor777.im/siteadmin/skin/lobby_asset/common/common/promotion/img_tg_tx_c1_c3_tx.webp?manualVersion=1&amp;version=e3e3fd4832"
											data-blur="0" data-status="loaded" class="goDRiiBsuEuXD3W1NphN"
											style="width: 0.9rem; height: 0.9rem; background-position: 0px 0px; background-image: url(&quot;https://cdntoos.calor777.im/siteadmin/skin/lobby_asset/common/common/promotion/img_tg_tx_c1_c3_tx.webp?manualVersion=1&amp;version=e3e3fd4832&quot;); background-size: 100% 100%;">
											<i class="HI77FtRdvO4aiGOC1sgp"
												style="display: inline-flex; justify-content: center; align-items: center;">
												<svg-icon icon-class="img_txx--svgSprite:all" />
												<!-- <svg
													width="1em" height="1em" fill="currentColor" aria-hidden="true"
													focusable="false">
													<use xlink:href="#img_txx--svgSprite:all"></use>
												</svg> -->
											</i><span class="">C3</span></div>
									</div>
									<div class="agent-content">
										<p>Apostas Válidas 20000</p>
									</div>
								</div>
							</div>



						</div>
						<div class="tutorialDetail">
							<h1><strong>Um exemplo é o seguinte:</strong></h1>
							<p>Suponha que as apostas efetivas atuais de 0 a 10.000 receberão uma comissão de 100 (ou
								seja, 1%) para cada 10.000, e o apostas efetivas acima de 10.000 receberão uma comissão
								de 300 para cada 10.000. (ou seja, 3%), A foi o primeiro a descobrir oportunidades de
								negócios neste site e imediatamente desenvolveu B1, B2 e B3. B1 desenvolveu ainda mais
								C1 e C2. B2 desenvolveu sem subordinados, e a B3 desenvolveu o relativamente poderoso
								C3. No segundo dia, a aposta efetiva de B1 é 500, a aposta efetiva de B2 é 3.000, a
								aposta efetiva de B3 é 2.000, a aposta efetiva de C1 é 1.000, a aposta efetiva de C2 é
								2.000 e a aposta efetiva de C3 é de até 20.000. </p><span>Então o método de cálculo da
								renda entre eles é o seguinte: </span>
							<ul>
								<li><strong>1. Comissão de B1</strong> (contribuições diretas de C1 e C2) = (1000 +
									2000) * 1% = <em>30</em></li>
								<li><strong>2. Comissão de B2</strong> (sem subordinados) = (0+0) * 1% = <em> 0</em>
								</li>
								<li><strong>3. Comissão B3</strong> (contribuição direta de C3) = 20.000 * 3% =
									<em>600</em>
								</li>
								<li> <strong>4. Além das contribuições dos subordinados diretos B1, B2 e B3, a comissão
										de A também advém das contribuições dos demais subordinados C1, C2 e C3,
										conforme segue: </strong>
									<ul>
										<li><strong>( 1 )Comissão direta de A</strong>(contribuições diretas de B1, B2 e
											B3) = (500+3000+2000) * 3% = <em>165</em></li>
										<li><strong>( 2) Outras comissões de A</strong>(das contribuições C1,
											C2)=(1000+2000) * 2%= <em>60</em></li>
										<li><strong>(3)Comissão total de A </strong>(direto + outro) = 165+60 =
											<em>225</em>
										</li>
									</ul>
								</li>
								<li><strong>5. Resumo: </strong>
									<ul>
										<li><strong>(1) Equipe direta</strong>: refere-se aos subordinados desenvolvidos
											diretamente por A, ou seja, o primeiro nível de relacionamento com A,
											denominados coletivamente como equipe direta. </li>
										<li><strong>(2) Outras equipes</strong>: Refere-se àquelas que são desenvolvidas
											por subordinados de A. Possuem relacionamento de segundo nível com A ou
											superior, ou seja, subordinados de subordinados , e os subordinados dos
											subordinados.. etc., coletivamente referidos como outras equipes; como esse
											modelo de agência pode desenvolver subordinados ilimitados, para
											conveniência da explicação, este artigo toma apenas a estrutura de 2 níveis
											como exemplo. </li>
										<li><strong>(3) Descrição de A</strong>: O desempenho direto de A é 5.500 e o
											outro desempenho é 20.000 (devido ao poder de C3). O desempenho total é
											28.500 e a comissão correspondente a taxa é de 3%. Como B1 tem um desempenho
											total de 3.000 e desfruta de um desconto de apenas 1%, enquanto A tem um
											desempenho total de 28.500 e desfruta de uma taxa de desconto de 3%, então
											haverá uma diferença de desconto entre A e B1. A diferença é: 3% -1% =2%,
											essa diferença é a parte contribuída por C1 e C2 para A, então C1 e C2
											contribuem para A: (1000+2000)* 2%=60, não há diferença extrema entre A e
											B3, então C3 contribui para A A comissão de contribuição é 0. </li>
										<li><strong>(4) Descrição de B1</strong>: B1 tem subordinados C1 e C2. Como o
											desempenho direto é 3.000, o índice de desconto correspondente é de 1%.
										</li>
										<li><strong>(5) Explicação B2 </strong>: B2 pode ser preguiçoso e não se
											beneficiará se não desenvolver seus subordinados. </li>
										<li><strong>(6) Explicação B3</strong>: Embora B3 tenha aderido relativamente
											tarde e seja subordinado de A, seu subordinado C3 é muito poderoso e tem um
											desempenho direto de 20.000, permitindo que B3 diretamente desfrutar de
											comissões mais elevadas.A proporção é de 3%. </li>
										<li><strong>(7) Resumo das regras</strong>: Não importa quando você ingressa, de
											quem você é subordinado, não importa em que nível você está, sua renda nunca
											será afetada e você não sofre mais as perdas dos subordinados dos outros., o
											desenvolvimento não é restrito. Este é um modelo de agência absolutamente
											justo e imparcial, e ninguém estará sempre por baixo só porque entrou tarde.
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</section>
				</div>
			</div>
		</section>
		
	</div>
</template>

<script>
	import {
		shareIndex
	} from "@/apis/modules/share.js";
	import QRCode from 'qrcode';
	import {
		mapState
	} from 'vuex';
	export default {
		computed: {
			...mapState(['popList', 'userInfo'])
		},
		data() {
			return {
				shareInfo: {},
				// {{origin}}/?id={{userInfo.id}}
				origin: window.location.origin,
			}
		},
		mounted() {
			this.shareIndex();
		},
		methods: {
			shareIndex() {
				shareIndex({
					activity: 0,
				}).then(({
					data
				}) => {
					this.shareInfo = data;
					this.generateQRCode();
				})
			},
			generateQRCode() {
				// let width = this.width,
				//         height = this.height;
				//       QRCode.toCanvas(
				//         document.getElementById(this.canvasId),
				//         this.content,
				//         { width, height, toSJISFunc: QRCode.toSJIS },
				//         error => {}
				//       );

				const canvas = this.$refs.qrcode_Canvas;
				console.log(canvas)
				QRCode.toCanvas(canvas, `${this.origin}/?id=${this.userInfo.id}`, {
					width: 78,
					height: 78,
					toSJISFunc: QRCode.toSJIS
				}, error => {
					if (error) console.error(error, 'QRCode')
				})
			},
		},
		created() {

		}
	}
</script>

<style scoped>
	
	.ZuLywNAJTm4ylsXALdMq {
	    color: var(--theme-primary-color)
	}
	.QkbUm1b1I2_PDqWH0vrH .t0HiyFKEvEAQH2C_LBRk svg {
	    font-size: .9rem;
		width: .9rem;
		height: .9rem;
	}
	.QkbUm1b1I2_PDqWH0vrH .t0HiyFKEvEAQH2C_LBRk svg{
		font-size: .9rem;
		width: .9rem;
		height: .9rem;
	}

	.QkbUm1b1I2_PDqWH0vrH .SSkNTcw8v2f7HdeEfu0Q .common-agent-avatar {
		left: calc(50% - .45rem);
		position: absolute;
		top: -.45rem
	}

	.QkbUm1b1I2_PDqWH0vrH .SSkNTcw8v2f7HdeEfu0Q .agent-content p {
		height: .36rem;
		overflow: visible
	}

	.QkbUm1b1I2_PDqWH0vrH .SSkNTcw8v2f7HdeEfu0Q {
		background-color: var(--theme-main-bg-color);
		border: .01rem solid var(--theme-color-line);
		border-radius: .1rem;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		color: var(--theme-text-color-lighten);
		font-size: .2rem;
		position: relative;
		text-align: center
	}

	.QkbUm1b1I2_PDqWH0vrH .SSkNTcw8v2f7HdeEfu0Q .agent-content {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		color: var(--theme-text-color-darken);
		display: -ms-flexbox;
		display: flex;
		justify-content: center;
		line-height: 1.3;
		min-height: .75rem;
		padding: .55rem .1rem .2rem
	}

	.QkbUm1b1I2_PDqWH0vrH .SSkNTcw8v2f7HdeEfu0Q .agent-content em {
		color: var(--theme-text-color-darken)
	}

	.QkbUm1b1I2_PDqWH0vrH .SSkNTcw8v2f7HdeEfu0Q .agent-content p {
		height: .36rem;
		overflow: visible
	}

	.QkbUm1b1I2_PDqWH0vrH .SSkNTcw8v2f7HdeEfu0Q.hasDescibe .agent-content {
		color: var(--theme-text-color-darken);
		line-height: 1.3;
		padding: .7rem .1rem .25rem
	}

	.QkbUm1b1I2_PDqWH0vrH .SSkNTcw8v2f7HdeEfu0Q .agent-describe {
		border-top: .01rem solid var(--theme-color-line);
		color: var(--theme-text-color-darken);
		font-size: .22rem;
		line-height: 1.4;
		padding: .15rem 0
	}

	.QkbUm1b1I2_PDqWH0vrH {
		background-color: transparent
	}

	.QkbUm1b1I2_PDqWH0vrH .ehK05_RISRn4AGaVcPas {
		margin-left: .2rem;
		margin-top: .12rem;
		position: static
	}

	.QkbUm1b1I2_PDqWH0vrH .SSkNTcw8v2f7HdeEfu0Q {
		border-radius: .14rem
	}

	.QkbUm1b1I2_PDqWH0vrH .SSkNTcw8v2f7HdeEfu0Q .agent-content {
		min-height: .45rem
	}

	.HI77FtRdvO4aiGOC1sgp {
		color: var(--theme-secondary-color-success);
	}

	.H_OpJ5CVLNPLrhbyCEGt {
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		min-height: 9rem
	}

	.H_OpJ5CVLNPLrhbyCEGt .BhbqCC7ckKHMVKhbpG5P {
		color: var(--theme-text-color-darken)
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		padding-bottom: .3rem
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B {
		height: 1.2rem;
		position: relative;
		width: 100%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B ._27z2i1Wtl19__pE123a {
		background-color: rgba(var(--theme-main-bg-color-hex), .9);
		border-radius: .1rem;
		color: var(--theme-text-color-darken);
		font-size: .18rem !important;
		max-width: 2.2rem;
		min-width: 1.6rem;
		padding: .05rem;
		text-align: center;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%)
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m {
		height: 1.9rem;
		position: relative;
		width: 7rem
	}


	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m .fnnriUD19roJSo7MqL0g {
		font-size: 1.8rem;
		left: 0;
		position: absolute
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m .fnnriUD19roJSo7MqL0g svg {
		width: 1.8rem;
		height: 1.8rem;
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m .PozORZAmuay1ZFPORsx6 {
		font-size: 1.8rem;
		left: 50%;
		position: absolute;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%)
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m .PozORZAmuay1ZFPORsx6 svg {
		width: 1.8rem;
		height: 1.8rem;
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m .DOVAU9KBCWgSF17fo9XY {
		font-size: 1.8rem;
		position: absolute;
		right: 0
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m .DOVAU9KBCWgSF17fo9XY svg {
		width: 1.8rem;
		height: 1.8rem;
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m .vj3waVbdcpVsr3CVecHi {
		font-size: 5.2rem;
		left: -47%;
		position: absolute
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m .vj3waVbdcpVsr3CVecHi svg {
		width: 5.2rem;
		height: 5.2rem;
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m .NpBXLYD8gen1pdTgKgcU {
		font-size: 5.2rem;
		left: 27%;
		position: absolute
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m .NpBXLYD8gen1pdTgKgcU svg {
		width: 5.2rem;
		height: 5.2rem;
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m .PfOUnpTsOZxU6WLhsunW {
		font-size: 5.2rem;
		left: 72%;
		position: absolute
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m .PfOUnpTsOZxU6WLhsunW svg {
		width: 5.2rem;
		height: 5.2rem;
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m ._27z2i1Wtl19__pE123a {
		position: absolute;
		top: 60%;
		z-index: 2
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m ._27z2i1Wtl19__pE123a em {
		color: var(--theme-secondary-color-finance)
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m ._27z2i1Wtl19__pE123a.fnnriUD19roJSo7MqL0g {
		left: 10%;
		position: absolute
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m ._27z2i1Wtl19__pE123a.PozORZAmuay1ZFPORsx6 {
		left: 50%;
		position: absolute
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m ._27z2i1Wtl19__pE123a.DOVAU9KBCWgSF17fo9XY {
		left: auto;
		position: absolute;
		right: 6%;
		-webkit-transform: translateX(50%);
		transform: translateX(50%)
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m ._27z2i1Wtl19__pE123a.vj3waVbdcpVsr3CVecHi {
		left: -6%;
		position: absolute;
		top: 10%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m ._27z2i1Wtl19__pE123a.NpBXLYD8gen1pdTgKgcU {
		position: absolute;
		right: 1%;
		top: 10%;
		-webkit-transform: translateX(50%);
		transform: translateX(50%)
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m ._27z2i1Wtl19__pE123a.PfOUnpTsOZxU6WLhsunW {
		left: 108%;
		position: absolute;
		top: 10%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ {
		height: 1.6rem;
		position: relative
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ .PzbFhwT8k4imTMJond8w {
		font-size: 1.3rem;
		left: 12%;
		position: absolute;
		top: 10%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ .PzbFhwT8k4imTMJond8w svg {
		width: 1.3rem;
		height: 1.3rem;
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ .ucqLARuFLFTYHIhsAHED {
		font-size: 1.5rem;
		left: 30%;
		position: absolute;
		top: 10%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ .ucqLARuFLFTYHIhsAHED svg {
		width: 1.5rem;
		height: 1.5rem;
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ .OshgYWQfhY0IfRJ7Sbc7 {
		font-size: 1.3rem;
		left: 75%;
		position: absolute;
		top: 10%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ .OshgYWQfhY0IfRJ7Sbc7 svg {
		width: 1.3rem;
		height: 1.3rem;
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ ._27z2i1Wtl19__pE123a {
		position: absolute;
		top: .5rem;
		z-index: 2
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ ._27z2i1Wtl19__pE123a em {
		color: var(--theme-secondary-color-finance)
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ ._27z2i1Wtl19__pE123a.PzbFhwT8k4imTMJond8w {
		left: 18%;
		position: absolute
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ ._27z2i1Wtl19__pE123a.ucqLARuFLFTYHIhsAHED {
		left: 42%;
		position: absolute
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ ._27z2i1Wtl19__pE123a.OshgYWQfhY0IfRJ7Sbc7 {
		left: auto;
		position: absolute;
		right: 6%;
		-webkit-transform: translateX(50%);
		transform: translateX(50%)
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .RmHZ3oVTU8mQ4XuJWB7_ {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		justify-content: space-between;
		margin-top: .45rem;
		padding: 0 .55rem;
		width: 100%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .RmHZ3oVTU8mQ4XuJWB7_>div {
		width: 2.6rem
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .M0HpRLR01mBt_PBm3WJL {
		-ms-flex-align: center;
		align-items: center;
		background-color: var(--theme-main-bg-color);
		border: .01rem solid var(--theme-color-line);
		border-radius: .1rem;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: .2rem;
		min-height: 1.8rem;
		padding: .2rem;
		width: 7rem
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .M0HpRLR01mBt_PBm3WJL em {
		color: var(--theme-secondary-color-finance)
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta {
		color: var(--theme-text-color-darken);
		font-size: .22rem;
		line-height: 1.64;
		margin-left: .2rem;
		width: 100%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta>p {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		justify-content: space-between
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta>p>span {
		margin-right: .05rem;
		text-align: left
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta>p>span:lang(zh_CN),
	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta>p>span:lang(zh_TW) {
		margin-right: auto;
		width: 50%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .M0HpRLR01mBt_PBm3WJL>div:first-child {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		margin-left: .5rem
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc {
		padding-bottom: .2rem
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .RmHZ3oVTU8mQ4XuJWB7_ {
		padding: 0;
		width: 95%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .RmHZ3oVTU8mQ4XuJWB7_>div {
		width: 2.1rem
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .M0HpRLR01mBt_PBm3WJL {
		border-radius: .14rem;
		position: relative;
		width: 100%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta {
		margin-left: .3rem
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta>p {
		-ms-flex-pack: start;
		justify-content: flex-start;
		text-align: left
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B {
		position: relative;
		width: 100%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m {
		width: 75%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m .vj3waVbdcpVsr3CVecHi {
		left: -55%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m .NpBXLYD8gen1pdTgKgcU {
		left: 16.5%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m .PfOUnpTsOZxU6WLhsunW {
		left: 57%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m ._27z2i1Wtl19__pE123a {
		top: 1.2rem
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m ._27z2i1Wtl19__pE123a.vj3waVbdcpVsr3CVecHi {
		left: 2%;
		top: 10%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m ._27z2i1Wtl19__pE123a.NpBXLYD8gen1pdTgKgcU {
		left: auto;
		right: 28%;
		top: 191%;
		-webkit-transform: translateX(50%);
		transform: translateX(50%)
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.xXGveCt3o5i1zYQHRp0m ._27z2i1Wtl19__pE123a.PfOUnpTsOZxU6WLhsunW {
		left: auto;
		right: 1%;
		top: 10%;
		-webkit-transform: translateX(50%);
		transform: translateX(50%)
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ {
		width: 100%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ .PzbFhwT8k4imTMJond8w {
		font-size: 1.45rem;
		left: 7%;
		top: 6%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ .ucqLARuFLFTYHIhsAHED {
		font-size: 1.7rem;
		left: 25%;
		top: 3%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ .OshgYWQfhY0IfRJ7Sbc7 {
		font-size: 1.45rem;
		left: 72%;
		top: 6%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ ._27z2i1Wtl19__pE123a {
		font-size: .18rem;
		top: 62%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ ._27z2i1Wtl19__pE123a.PzbFhwT8k4imTMJond8w {
		left: 18%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ ._27z2i1Wtl19__pE123a.ucqLARuFLFTYHIhsAHED {
		left: 49%
	}

	.H_OpJ5CVLNPLrhbyCEGt .VhvyzHpkqTuu7rED66Rc .dl2uB2pr2WsrCKrzc80B.qpR6IFJOg7xxFdqGUOfJ ._27z2i1Wtl19__pE123a.OshgYWQfhY0IfRJ7Sbc7 {
		left: auto;
		right: 16%;
		-webkit-transform: translateX(50%);
		transform: translateX(50%)
	}

	.MZG2AG46nbxCf7Pj94bu {
		-ms-flex-align: center;
		-ms-flex-pack: start;
		align-items: center;
		background: var(--theme-main-bg-color);
		border-radius: .1rem;
		-webkit-box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		display: -ms-flexbox;
		display: flex;
		font-size: .2rem;
		height: 1rem;
		justify-content: flex-start;
		padding: 0 .2rem;
	}

	.cX3I4wr8lagPbzMNAc5i {
		-ms-flex-align: start;
		align-items: flex-start;
		display: -ms-flexbox;
		display: flex;
		min-height: 1.35rem;
		padding-bottom: .06rem;
		padding-top: .2rem
	}

	.cX3I4wr8lagPbzMNAc5i .my-scrollbar-bar.my-scrollbar-horizontal {
		bottom: 0
	}

	.cX3I4wr8lagPbzMNAc5i>div {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		min-width: .95rem
	}

	.cX3I4wr8lagPbzMNAc5i>div:not(:last-child) {
		margin-right: .1rem
	}

	.cX3I4wr8lagPbzMNAc5i>div>img {
		border-radius: 50%
	}

	.my-scrollbar-content {
		overflow-y: scroll;
	}

	.ant-select-selection {
		border: 1px solid var(--theme-color-line);
	}

	.ant-select-selection:hover {
		border-color: var(--theme-ant-primary-color-13);
		border-right-width: 1px !important
	}

	.cX3I4wr8lagPbzMNAc5i>div>span {
		word-wrap: break-word;
		display: inline-block;
		font-size: .22rem;
		line-height: 1.15;
		max-width: 1.1rem;
		padding-top: .1rem;
		text-align: center;
		color: var(--theme-text-color);
	}

	.XGEOwGXgDL9a2SebPqvj {
		color: var(--theme-secondary-color-finance);
		width: 1rem;
		height: 1rem;
		position: absolute;
	}

	.t0HiyFKEvEAQH2C_LBRk span.isBigAgent {
		color: var(--theme-secondary-color-help-text-color);
		right: .09rem
	}

	.t0HiyFKEvEAQH2C_LBRk span {
		bottom: -.01rem;
		color: var(--theme-primary-font-color);
		font-size: .18rem;
		line-height: .32rem;
		position: absolute;
		right: .04rem
	}

	.cX3I4wr8lagPbzMNAc5i .zsbbPf1TFxnlj15CZdFo {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		border: .01rem solid var(--theme-color-line);
		border-radius: 50%;
		color: var(--theme-primary-color);
		display: -ms-flexbox;
		display: flex;
		font-size: .36rem;
		height: .7rem;
		justify-content: center;
		padding: 0;
		width: .7rem
	}

	.tutorialDetail {
		border-radius: .14rem;
		padding: .2rem
	}

	.tutorialDetail {
		background-color: var(--theme-main-bg-color);
		border: .01rem solid var(--theme-color-line);
		border-radius: .1rem;
		color: var(--theme-text-color-darken);
		font-size: .24rem;
		line-height: .4rem;
		padding: .3rem;
		margin-bottom: .5rem;
	}

	.COsess8CMJ_BLuGwYYdj {
		color: var(--theme-primary-color);

	}

	.goDRiiBsuEuXD3W1NphN {
		position: relative;
	}

	.ant-btn,
	.ant-select-dropdown {
		border-radius: .14rem
	}

	html .ant-btn.ant-btn-primary {
		background-color: var(--theme-primary-color);
		border-color: var(--theme-primary-color)
	}

	html .ant-btn.ant-btn-primary {
		color: var(--theme-primary-font-color);
		text-shadow: none
	}

	.MZG2AG46nbxCf7Pj94bu .EuylP4i2GhrgzkTAbaf0 {
		cursor: pointer;
		height: 100%;
		position: relative
	}

	.MZG2AG46nbxCf7Pj94bu .EuylP4i2GhrgzkTAbaf0>span:first-child {
		display: inline-block;
		height: .8rem;
		width: 1.04rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf {
		-ms-flex-align: start;
		-ms-flex-pack: justify;
		align-items: flex-start;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: .2rem .2rem 0
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .Cp3sCgsE5FnXQBFpShix {
		margin-right: .2rem;
		width: 1.5rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .Cp3sCgsE5FnXQBFpShix .ant-btn-link {
		background-color: var(--theme-primary-color);
		border-radius: 0 0 .14rem .14rem;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		color: var(--theme-primary-font-color);
		font-size: .24rem;
		height: auto;
		min-height: .4rem;
		padding: 0px;
		padding-bottom: .05rem;
		white-space: pre-wrap;
		/* width: 1.5rem; */
		text-align: center;
		word-break: break-word;
		cursor: pointer;
		line-height: 1.499;
		display: inline-block;
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .Cp3sCgsE5FnXQBFpShix .ant-btn-link[disabled] {
		background-color: var(--theme-disabled-bg-color) !important
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .Cp3sCgsE5FnXQBFpShix ._197iSp6iuweVeX9jjWqU {
		-ms-flex-pack: end;
		background: #fff;
		border: .01rem solid var(--theme-color-line);
		border-radius: .14rem .14rem .2rem .2rem;
		-webkit-box-sizing: content-box;
		box-sizing: content-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		justify-content: flex-end;
		min-height: 1.9rem;
		position: relative;
		width: 1.5rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .Cp3sCgsE5FnXQBFpShix ._197iSp6iuweVeX9jjWqU canvas {
		height: 1.3rem !important;
		margin: .1rem;
		width: 1.3rem !important;
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .Cp3sCgsE5FnXQBFpShix ._197iSp6iuweVeX9jjWqU .LKKxPSsoHwTWKNcyj1c8 {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		height: 1.5rem;
		justify-content: center;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1>.uQRA1q9mzX1o2pAJ5uZA {
		-ms-flex-pack: justify;
		color: var(--theme-text-color-lighten);
		display: -ms-flexbox;
		display: flex;
		font-size: .2rem;
		height: .29rem;
		justify-content: space-between;
		margin-bottom: .1rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1>.uQRA1q9mzX1o2pAJ5uZA>span:nth-child(2) {
		color: var(--theme-primary-color);
		cursor: pointer
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE {
		position: relative;
		width: 100%
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .ant-select {
		width: 100%
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .ant-select .ant-select-selection--single {
		border-radius: .1rem;
		color: var(--theme-text-color);
		height: 100%
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .ant-select .ant-select-selection__rendered {
		font-size: .2rem;
		padding: .2rem 0 .2rem .1rem;
		width: 100%
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .ant-select .ant-select-selection-selected-value {
		white-space: normal
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .ant-select .ant-select-arrow {
		position: absolute;
		right: 0;
		top: 0
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE #myLink-suffixIcon {
		text-wrap: nowrap;
		color: var(--theme-primary-color);
		cursor: pointer;
		font-size: .24rem;
		position: absolute;
		right: 0;
		top: -.25rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .COsess8CMJ_BLuGwYYdj {
		font-size: .24rem;
		position: absolute;
		right: .2rem;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%)
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM {
		width: 4.9rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qiuoPrJlJq7AoDdC_Jp1 .uQRA1q9mzX1o2pAJ5uZA {
		font-size: .24rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE {
		position: relative;
		width: 100%
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .ant-select {
		width: 100%
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .ant-select .ant-select-selection--single {
		border-radius: .1rem;
		height: 100%;
		max-width: 5rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .ant-select .ant-select-selection__rendered {
		font-size: .22rem;
		line-height: normal;
		margin: 0;
		overflow: hidden;
		padding: .2rem .59rem .2rem .2rem;
		text-overflow: ellipsis;
		width: auto
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qiuoPrJlJq7AoDdC_Jp1 .Z1AgDMke12GSrmYdP6eE .COsess8CMJ_BLuGwYYdj {
		font-size: .3rem;
		position: absolute;
		right: .2rem;
		top: 50%;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%)
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qodAUmIjz1fK0NTjDSLf {
		-ms-flex-align: start;
		-ms-flex-pack: justify;
		align-items: flex-start;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		font-size: .24rem;
		justify-content: space-between;
		margin-top: .2rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qodAUmIjz1fK0NTjDSLf>div {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: .1rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qodAUmIjz1fK0NTjDSLf>div label {
		color: var(--theme-text-color-lighten);
		margin-right: .1rem
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qodAUmIjz1fK0NTjDSLf>div>span {
		color: var(--theme-text-color-darken)
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qodAUmIjz1fK0NTjDSLf>div>span.dOhCK6tlyL9yiDmj_NGc {
		color: var(--theme-text-color-lighten)
	}

	.ye40pj_izwzKAVwAomfq .HuBHjdDhrs3shwX2I3cf .X7q4mjP_LFhFIJRXfYuM .qodAUmIjz1fK0NTjDSLf>div .COsess8CMJ_BLuGwYYdj {
		color: var(--theme-primary-color);
		margin-left: .1rem
	}


	.MZG2AG46nbxCf7Pj94bu .EuylP4i2GhrgzkTAbaf0>span:nth-child(2) {
		-ms-flex-align: center;
		-ms-flex-pack: center;
		align-items: center;
		background-color: var(--theme-primary-color);
		border-radius: 50%;
		color: var(--theme-primary-font-color);
		cursor: pointer;
		display: -ms-flexbox;
		display: flex;
		height: .24rem;
		justify-content: center;
		left: .82rem;
		position: absolute;
		top: .05rem;
		width: .24rem
	}

	.MZG2AG46nbxCf7Pj94bu .EuylP4i2GhrgzkTAbaf0>span:nth-child(2) svg {
		color: var(--theme-primary-font-color);
		font-size: .12rem
	}

	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 {
		padding: .2rem .2rem .2rem .3rem
	}

	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 .FAnV4xyvhwu6V8VvjxOg,
	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 .mwkrCf7T9Q6EuRxoGPvN {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex
	}

	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 .FAnV4xyvhwu6V8VvjxOg span:first-child,
	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 .mwkrCf7T9Q6EuRxoGPvN span:first-child {
		display: inline-block;
		padding-right: .1rem
	}

	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 .mwkrCf7T9Q6EuRxoGPvN {
		-ms-flex-align: center;
		align-items: center;
		color: var(--theme-text-color-lighten);
		display: -ms-flexbox;
		display: flex
	}

	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 .mwkrCf7T9Q6EuRxoGPvN>span:last-child {
		color: var(--theme-text-color-darken)
	}

	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 .FAnV4xyvhwu6V8VvjxOg {
		color: var(--theme-text-color-lighten);
		margin-bottom: .1rem
	}

	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 .FAnV4xyvhwu6V8VvjxOg>span:last-child {
		color: var(--theme-text-color-darken)
	}

	.MZG2AG46nbxCf7Pj94bu .tbJe4aWRACpPqK8U19l3 .H2Y9pGRG8VdCF50ZdZlE {
		-ms-flex-negative: 0;
		color: var(--theme-primary-color);
		flex-shrink: 0;
		font-size: .24rem;
		line-height: 1
	}

	.MZG2AG46nbxCf7Pj94bu .KzvcBdzMHhzwjIUa04tc {
		-ms-flex-positive: 1;
		flex-grow: 1;
		padding: .2rem .2rem .2rem .3rem
	}

	.MZG2AG46nbxCf7Pj94bu .KzvcBdzMHhzwjIUa04tc .FmRERCI89HukhH03x3QV {
		-ms-flex-align: center;
		align-items: center;
		color: var(--theme-text-color-lighten);
		display: -ms-flexbox;
		display: flex
	}

	.MZG2AG46nbxCf7Pj94bu .KzvcBdzMHhzwjIUa04tc .currencyAmount {
		font-size: .24rem
	}

	.MZG2AG46nbxCf7Pj94bu .p0wYtn0oOg74hhZSLc7V {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		justify-content: space-between;
		padding: .2rem .1rem .2rem 0;
		width: 2.4rem
	}

	.MZG2AG46nbxCf7Pj94bu .p0wYtn0oOg74hhZSLc7V button {
		height: .4rem;
		padding: 0;
		width: 1rem
	}

	.nfz1NBFeLVm6IIOo2CLj {
		background: none;
		overflow: scroll;
		width: 100%
	}

	.ant-spin-container {
		position: relative;
		-webkit-transition: opacity .3s;
		transition: opacity .3s
	}

	.ant-spin-container:after {
		background: #fff;
		bottom: 0;
		content: "";
		display: none\9;
		height: 100%;
		left: 0;
		opacity: 0;
		pointer-events: none;
		position: absolute;
		right: 0;
		top: 0;
		-webkit-transition: all .3s;
		transition: all .3s;
		width: 100%;
		z-index: 10
	}

	.nfz1NBFeLVm6IIOo2CLj .currencyAmount .suffix {
		color: var(--theme-text-color-darken)
	}

	.nfz1NBFeLVm6IIOo2CLj .QBeJPwd_uejjKLAsjWcb {
		background-color: var(--theme-main-bg-color);
		border-radius: 0 0 .14rem .14rem
	}

	.nfz1NBFeLVm6IIOo2CLj .Zw36nTThA8kC3264AaHK {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		color: var(--theme-text-color-darken);
		display: -ms-flexbox;
		display: flex;
		font-size: .3rem;
		justify-content: space-between;
		padding: .2rem 0;
		width: 100%
	}

	.nfz1NBFeLVm6IIOo2CLj .hrp2W0A6qvg1zg4ysky3 {
		margin: .15rem .15rem 0;
		padding: .05rem
	}

	.nfz1NBFeLVm6IIOo2CLj .QV03qBSNYehJplfhfCcw {
		overflow: hidden;
		padding: 0 .2rem .2rem
	}

	.MZG2AG46nbxCf7Pj94bu {
		-ms-flex-align: start;
		align-items: flex-start;
		border-bottom: .01rem solid var(--theme-color-line);
		border-radius: .14rem .14rem 0 0;
		-webkit-box-shadow: none;
		box-shadow: none;
		font-size: .22rem;
		height: auto
	}

	.MZG2AG46nbxCf7Pj94bu .EuylP4i2GhrgzkTAbaf0 {
		margin-right: .2rem
	}

	.MZG2AG46nbxCf7Pj94bu .EuylP4i2GhrgzkTAbaf0>span:first-child {
		display: inline-block;
		width: 1.3rem
	}

	.MZG2AG46nbxCf7Pj94bu .EuylP4i2GhrgzkTAbaf0>span:first-child>img {
		width: 100% !important
	}

	.MZG2AG46nbxCf7Pj94bu .EuylP4i2GhrgzkTAbaf0>span:nth-child(2) {
		height: .24rem;
		left: 1.12rem;
		position: absolute;
		top: .05rem;
		width: .24rem
	}

	.MZG2AG46nbxCf7Pj94bu .EuylP4i2GhrgzkTAbaf0>span:nth-child(2) svg {
		font-size: .16rem
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju {
		-ms-flex-positive: 1;
		flex-grow: 1;
		padding: .2rem 0
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju .tbJe4aWRACpPqK8U19l3 {
		-ms-flex-align: start;
		-ms-flex-pack: justify;
		align-items: flex-start;
		color: var(--theme-text-color-darken);
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		font-size: .22rem;
		justify-content: space-between;
		margin-top: .2rem;
		padding: 0;
		width: 100%
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju .tbJe4aWRACpPqK8U19l3 label {
		color: var(--theme-text-color-lighten);
		margin-right: .1rem
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju .KzvcBdzMHhzwjIUa04tc {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		color: var(--theme-text-color-darken);
		display: -ms-flexbox;
		display: flex;
		font-size: .22rem;
		justify-content: space-between;
		padding: 0
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju .KzvcBdzMHhzwjIUa04tc label {
		color: var(--theme-text-color-lighten);
		margin-right: .1rem
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju .KzvcBdzMHhzwjIUa04tc .FmRERCI89HukhH03x3QV {
		-ms-flex-positive: 1;
		flex-grow: 1
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju .KzvcBdzMHhzwjIUa04tc button {
		font-size: .2rem;
		height: .5rem;
		line-height: 1.3;
		padding: 0;
		width: 1.2rem
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju .KzvcBdzMHhzwjIUa04tc button span {
		word-wrap: break-word;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		padding: 0 !important;
		overflow: hidden;
		padding-right: 0 !important;
		text-overflow: ellipsis;
		vertical-align: middle;
		white-space: pre-wrap
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju .KzvcBdzMHhzwjIUa04tc .ant-btn-success {
		margin-right: .2rem;
		background-color: var(--theme-disabled-bg-color);
		border-color: var(--theme-disabled-bg-color);
		color: var(--theme-disabled-font-color);
	}

	.MZG2AG46nbxCf7Pj94bu .skuC1f1NURrkI5OOZ7Ju .KzvcBdzMHhzwjIUa04tc .currencyAmount {
		font-size: .22rem
	}

	[data-skin-layout="9"][data-device=desktop] .MZG2AG46nbxCf7Pj94bu,
	[data-skin-layout="9"][data-device=desktop] .ant-calendar-date-panel,
	[data-skin-layout="9"][data-device=desktop] .boxShadow,
	[data-skin-layout="9"][data-device=desktop] .myTitle {
		-webkit-box-shadow: 0 0 .1rem 0 #00d9ff !important;
		box-shadow: 0 0 .1rem 0 #00d9ff !important
	}

	.M0HpRLR01mBt_PBm3WJL {
		-ms-flex-align: center;
		align-items: center;
		background-color: var(--theme-main-bg-color);
		border: .01rem solid var(--theme-color-line);
		border-radius: .1rem;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: .2rem;
		min-height: 1.8rem;
		padding: .2rem;
		width: 7rem
	}

	.M0HpRLR01mBt_PBm3WJL em {
		color: var(--theme-secondary-color-finance)
	}

	.M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta {
		color: var(--theme-text-color-darken);
		font-size: .22rem;
		line-height: 1.64;
		margin-left: .2rem;
		width: 100%
	}

	.M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta>p {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		justify-content: space-between
	}

	.M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta>p>span {
		margin-right: .05rem;
		text-align: left
	}

	.M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta>p>span:lang(zh_CN),
	.M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta>p>span:lang(zh_TW) {
		margin-right: auto;
		width: 50%
	}

	.M0HpRLR01mBt_PBm3WJL>div:first-child {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-direction: column;
		flex-direction: column;
		margin-left: .5rem
	}


	.M0HpRLR01mBt_PBm3WJL {
		border-radius: .14rem;
		position: relative;
		width: 100%
	}

	.M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta {
		margin-left: .3rem
	}

	.M0HpRLR01mBt_PBm3WJL .xfLSbGUImFz61wP4W7ta>p {
		-ms-flex-pack: start;
		justify-content: flex-start;
		text-align: left
	}
</style>
