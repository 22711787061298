<template>
	<div class="" v-bind="$attrs" style="min-height: 100vh;background-color: var(--theme-bg-color);">
		<div class="C3g78JbIzYOGJevcEFJk">
			<div class="w8hvCePe2cr4lZDHWfAo" style="display: flex;justify-content: space-between;">
				<p class="AZ93sQuJsiyisJNOP80k "
				  :class="{
					  active:isTabActive==1
				  }"
				  @click.stop="isTabActive=1" 
				  >
					<!-- _Saldo:"Saldo", -->
					<span>{{ $t('withdraw._Saldo') }}</span><span class="LzGV_Y6Kh_gIgFmxJ9M3">{{moneyInfo.balance_coin_str}}</span>
					<span @click.stop="animate">
						<svg-icon className="Gn5USDlcXrykBFgJY_Lc"
							icon-class="comm_icon_sx--svgSprite:all" />
					</span>
				</p>
				
				<p class="AZ93sQuJsiyisJNOP80k" 
					v-if="moneyInfo.balance_bonus>0"
					:class="{
						active:isTabActive==2
					}"
					@click.stop="isTabActive=2"
				>
					<span>{{ $t('withdraw._Bonus') }}</span><span class="LzGV_Y6Kh_gIgFmxJ9M3">{{ moneyInfo.balance_bonus_str }}</span>
					<span @click.stop="animate">
						<svg-icon className="Gn5USDlcXrykBFgJY_Lc"
							icon-class="comm_icon_sx--svgSprite:all" />
					</span>
				</p>
			</div>
			<div class="w8hvCePe2cr4lZDHWfAo">
				<p class="TBEN6ItimocPXMsKC0So"><span class="O_aIJGV00l8icMkw8dGQ"><span>{{ $t('withdraw._Vocêprecisaapostar') }}
						</span><span @click="tabValue(1,true)" class="Zoa_YKjt9A5jczOFotnl" v-if="moneyInfo.withdraw">{{moneyInfo.withdraw.water_limit_str}}</span><span>{{ $t('withdraw._sacardinheiro') }}</span></span></p>
			</div>
			
		</div>
		<!-- current -->
		<div class="hypBigUdukqlsd7JuW8b z-ant-input">
			<div class="xyst1Xgxws_PsqrHlm4A gD0G2pAAJZZTJ1n0KQXv" v-if="$attrs.contaDeLevantamento" @click="tabValue(2,false)" >
				<!-- {{$attrs.contaDeLevantamentoFromDta}} -->
				<antST @_click="_click_antS"  :contaDeLevantamentoFromDta="$attrs.contaDeLevantamentoFromDta" :dados="[{name:'游戏余额',type:'1'},{name:'奖金余额',type:'1'}]" :modelvalue="modelvalue" :disabled="!$attrs.contaDeLevantamento"></antST>
				<span  style="display: flex;" @click="tabValue(2,true)">
					<svg-icon className="tenSlis"
					 icon-class="icon_tx_txgl--svgSprite:all" />
				</span>
			</div>
			<div class=" gD0G2pAAJZZTJ1n0KQXv" v-if="!$attrs.contaDeLevantamento" @click="tabValue(2,true)">
				<div class="constListt">
					<span  style="display: flex;align-items: center;" >
						<svg-icon style="margin-right: .15rem;" className="tenSlis"
						 icon-class="icon_tx_txgl--svgSprite:all" />
						 <span style="color: var(--theme-text-color-placeholder);">Adicionar Conta</span>
					</span>
					
					<span class="">
						<svg-icon className="stcbSvg"
						 icon-class="comm_icon_fh--svgSprite:all" />
					</span>
				</div>
				
			</div>
			<!-- 1游戏余额，2奖金余额 -->
			<div class="gD0G2pAAJZZTJ1n0KQXv" @click="tabValue(2,false)">
				<antInput  :clearable='false'  
				 @onInput="_onInput" :modelValue="forData.amount" form-key='amount'
				 :disabled="!$attrs.contaDeLevantamento" 
					placeholder="Mínimo10, Máximo1000000">
					<div slot="left"
						style="color: var(--theme-text-color-darken);font-size:.18rem;display: flex;align-items: center;">
						R$
					</div>
					<div slot="right-icon" @click="sConmenYCnsg"  style="color: var(--theme-primary-color);font-size: .22rem;">
						{{ $t('withdraw._Tudo') }}
					</div>
				</antInput>

			</div>
			<!-- <div class="iPb_mFnHjTnb1GI_9aKM Cod2k0E5Pzgd4QEEusl7"><span class="JhjuJSt_Jr_L0kKlzzGn">{{ $t('withdraw._Estataxade') }}</span><span class="HZbJszKnd2frlT9r2NxC">{{ $t('withdraw._Vejaasregrasparadetalhes') }}</span></div> -->
			<div class="Ou1aviPriFX421w97dGA">
				<span>{{ $t('withdraw._VerificarSenhadeSaque') }}</span>
				<i @click="isShowNum=!isShowNum" :class="{
						isShowNum:isShowNum
					}">
					<svg-icon className="ZaAWGnvFuhPwlBk40od_"
						:icon-class="`${isShowNum?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'}`" />
				</i>
			</div>
			<div class="gD0G2pAAJZZTJ1n0KQXv">
				<ant-input-password :isShow="isShowNum" :disabled="!$attrs.contaDeLevantamento" @change="newPassword" style="margin-bottom: .5rem;">
				</ant-input-password>
			</div>

			<div class="DPP1EFmWKVe_UzDwWXH8">
				<van-button 
				 @click="$router.push({name:'yuebao'})"
				 class="ant-btn ant-btn-default whKvP0fKErR_J8VBaFio S7NE4JBCHiQzgcmndDQc" type="default">
				 {{ $t('withdraw._Ganharjuros') }}
					<div class="uh6csli2MHzFz87__Phx tool-tips oadDtBg3KY43TYIIVK_e"
						style="--cu-top: -0.12rem; --cu-right: unset; --cu-left: 0%;">
						<p class="tool-tips-box sNgyWmhVEZVrbSGKDBra">Anual100%</p>
						<p class="tool-tips-tail YiNQnpSJUes7u5sqBZhp"></p>
					</div>
				</van-button>
				<van-button @click="formImes" class="ant-btn ant-btn-primary whKvP0fKErR_J8VBaFio" type="primary">
					{{ $t('withdraw._Confirmarretirada') }}
				</van-button>
			</div>
		</div>
	</div>
</template>
<script>
	// 
	
	// @ is an alias to /src
	import withdrawHeader from "@/components/withdrawHeader/index.vue"
	import antInputPassword from "@/components/ant-input-password/index.vue"
	import antST from "./ant-s-t.vue"
	import antInput from "./input.vue"
	import { mapState } from 'vuex';
	export default {
		name: 'Home',
		components: {
			withdrawHeader,
			antInputPassword,
			antST,
			antInput
		},
		computed:{
			...mapState(['popList','userInfo','moneyInfo'])
		},
		data() {
			return {
				list: [],
				isShowNum: false,
				loading: false,
				finished: false,
				new_password: '',
				modelvalue:0,
				activitiesList: [],
				isTabActive:1,
				forData:{
					type:'1',
					amount:'',
					password:'',
					isConter:true,
				},
			};
		},
		created() {
		},
		mounted() {
			
		    // console.log(this.$attrs=3); // 输出：{ class: "parent-class", title: "Hello World", 'data-foo': "bar" }
		  },
		methods: {
			_click_antS(item,index){
				this.modelvalue=index;
				this.forData.type=item.type;
			},
			_onInput(v,k){
				this.forData[k]=v;
			},
			animate(e){
				this.getBalance();
				let target=e.target;
				let tagName=target.tagName.toLowerCase()
				if(tagName=='svg'){
					target.classList.add('animate__manimated');
					let time=setTimeout(()=>{
						clearTimeout(time)
						target.classList.remove('animate__manimated')
					},300)
				}
			},
			tabValue(key,_fs){
				if(!_fs){
					if(this.$attrs.contaDeLevantamento){
						
						return false;
					}
				}
				this.$router.push({name:'withdraw',query:{
					current:key
				}})
				this.$attrs.modelValue(key)
			},
			sConmenYCnsg(){
				
				if(this.$attrs.contaDeLevantamento){
					if(this.isTabActive==1){
					this.forData.amount=parseInt(this.moneyInfo.balance_coin) || 0;	
					}else{
						this.forData.amount=parseInt(this.moneyInfo.balance_bonus) || 0;
					}
					return false;
				}
			},
			formImes(){
				this.forData.type=this.isTabActive;
				this.$attrs.withdraw(this.forData)
			},
			areSixDigitsTheSame(str) {
				const regex = /(\d)\1{5}/;
				return regex.test(str);
			},
			newPassword(e) {
				this.forData.password = e;
			},
			getActivities() {
				activities({}).then(({
					code,
					data = {},
					msg
				}) => {
					// console.log(code,data,msg)
					this.activitiesList = data;
				})
			},
			
			onLoad() {
			},
		},
	};
</script>
<style>
	.gD0G2pAAJZZTJ1n0KQXv .van-icon {
		display: none;
	}

	.gD0G2pAAJZZTJ1n0KQXv .van-search__content {
		padding-left: .1rem;
	}
</style>
<style scoped>
	.DPP1EFmWKVe_UzDwWXH8{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.DPP1EFmWKVe_UzDwWXH8 .ant-btn{
		flex: 1;
	}
	.S7NE4JBCHiQzgcmndDQc{
		margin-right: .2rem;
	}
	.uh6csli2MHzFz87__Phx {
	    height: .32rem;
	    left: var(--cu-left);
	    position: absolute;
	    right: var(--cu-right);
	    top: var(--cu-top);
	    width: -webkit-fit-content;
	    width: -moz-fit-content;
	    width: fit-content;
	    z-index: 100
	}
	
	.uh6csli2MHzFz87__Phx p {
	    margin-bottom: 0
	}
	
	.uh6csli2MHzFz87__Phx .YiNQnpSJUes7u5sqBZhp {
	    border-bottom: .07rem solid transparent;
	    border-left: .07rem solid var(--theme-secondary-color-error);
	    border-right: .07rem solid transparent;
	    height: 0;
	    width: 0
	}
	
	.uh6csli2MHzFz87__Phx .T5xNMynUJQws22ZME9hV {
	    border-bottom: .07rem solid transparent;
	    border-left: .07rem solid transparent;
	    border-right: .07rem solid var(--theme-secondary-color-error);
	    float: right
	}
	
	.uh6csli2MHzFz87__Phx .sNgyWmhVEZVrbSGKDBra {
	    background-color: var(--theme-secondary-color-error);
	    border-radius: .125rem .125rem .125rem 0;
	    color: #fff;
	    font-size: .14rem;
	    height: .22rem;
	    line-height: .22rem;
	    padding: 0 .08rem;
	    width: 100%
	}
	
	.uh6csli2MHzFz87__Phx .sNgyWmhVEZVrbSGKDBra:lang(my_MM) {
	    height: .26rem
	}
	
	.uh6csli2MHzFz87__Phx .CmN8CVsfet8ieqBIT7mi {
	    color: #ff0
	}
	
	.uh6csli2MHzFz87__Phx .nc_NIDTLmXeUSFFfADOl {
	    border-radius: .125rem .125rem 0 .125rem
	}
	.whKvP0fKErR_J8VBaFio{
		font-size: .24rem;
		    height: .7rem;
			    border-radius: .14rem;
				
				line-height: .36rem;
				font-weight: 300;
				font-size: .24rem;
	}
	.constListt{
		background-color: var(--theme-main-bg-color);
		    border-radius: .14rem;
		    width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
		    box-sizing: border-box;
		    border: 1px solid var(--theme-color-line);
		    font-size: .22rem;
		    height: .7rem;
		    position: relative;
		    line-height: .7rem;
		    text-indent: .05rem;
		    padding-left: .2rem;
		    padding-right: .2rem;
		    border: solid .02rem;
		    border-color: var(--theme-color-line);
	}
	.constListt .stcbSvg{
		width: .2rem;
		height: .2rem;
		    color: var(--theme-color-line);
			    -webkit-transform: rotate(180deg);
			    transform: rotate(180deg);
	}
	.Cod2k0E5Pzgd4QEEusl7 {
		display: block;
		font-size: .22rem;
		padding-bottom: .28rem;
		border-bottom: thin solid var(--theme-color-line);
		color: var(--theme-text-color);
		
	}

	.Ou1aviPriFX421w97dGA {
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: var(--theme-text-color);
		font-size: .22rem;
		height: .32rem;
		margin-top: .3rem;
		margin-bottom: .15rem;
	}

	.Ou1aviPriFX421w97dGA .ZaAWGnvFuhPwlBk40od_ {
		color: var(--theme-color-line);
	}

	.Ou1aviPriFX421w97dGA .isShowNum .ZaAWGnvFuhPwlBk40od_ {
		color: var(--theme-primary-color);
	}

	.HZbJszKnd2frlT9r2NxC {
		color: var(--theme-primary-color);
		margin-left: .1rem;
	}

	.hypBigUdukqlsd7JuW8b {
		padding: .3rem .2rem 0;
	}

	.gD0G2pAAJZZTJ1n0KQXv {
		margin-bottom: .5rem;
	}

	.xyst1Xgxws_PsqrHlm4A {
		display: flex;
		align-items: center;
		
	}


	.tenSlis {
		color: var(--theme-primary-color);
		font-size: .45rem;
		height: .45rem;
		width: .45rem;
		margin-left: .2rem;
	}

	.C3g78JbIzYOGJevcEFJk {
		-ms-flex-pack: justify;
		background-color: var(--theme-main-bg-color);
		height: auto;
		justify-content: space-between;
		padding: .2rem;
		box-sizing: border-box;
		width: 100%
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo {
		-ms-flex-pack: justify;
		display: -ms-flexbox;
		display: flex;
		/* -ms-flex-direction: column; */
		/* flex-direction: column; */
		font-size: .22rem;
		justify-content: space-between
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo>p {
		-ms-flex-align: center;
		align-items: center;
		line-height: .35rem
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo>p>span {
		line-height: .35rem
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo .AZ93sQuJsiyisJNOP80k {
		color: var(--theme-text-color-lighten);
		font-size: .24rem;
		margin-bottom: .15rem;
		border: .01rem solid var(--theme-color-line);
		    border-radius: .25rem;
		    color: var(--theme-text-color);
		    cursor: pointer;
			display: flex;
			align-items: center;
		    font-size: .22rem;
		    justify-content: center;
		    min-height: .5rem;
		    min-width: 1rem;
		    overflow: hidden;
		    padding: 0 .2rem;
		    text-align: center;
		    white-space: nowrap;
	}
	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo .AZ93sQuJsiyisJNOP80k.active{
		background-color: var(--theme-primary-color);
		    border: .01rem solid var(--theme-primary-color);
		    color: var(--theme-primary-font-color);
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo .AZ93sQuJsiyisJNOP80k .LzGV_Y6Kh_gIgFmxJ9M3 {
		/* color: var(--theme-secondary-color-finance) !important; */
		font-size: .24rem;
		font-weight: 700;
		margin-left: .05rem;
		margin-right: .1rem;
		position: relative;
		top: 0
	}
	

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo .AZ93sQuJsiyisJNOP80k .Gn5USDlcXrykBFgJY_Lc {
		/* color: var(--theme-primary-color); */
		font-size: .3rem;
		height: .3rem;
		width: .3rem;
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo .TBEN6ItimocPXMsKC0So {
		text-align: left;
		white-space: normal
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo .TBEN6ItimocPXMsKC0So span {
		color: var(--theme-text-color-lighten)
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo .TBEN6ItimocPXMsKC0So .Zoa_YKjt9A5jczOFotnl {
		border-bottom: thin solid var(--theme-primary-color);
		color: var(--theme-primary-color)
	}

	.C3g78JbIzYOGJevcEFJk .w8hvCePe2cr4lZDHWfAo .TBEN6ItimocPXMsKC0So .O_aIJGV00l8icMkw8dGQ>i {
		margin: 0 .05rem
	}

	.C3g78JbIzYOGJevcEFJk .N0JQr4MRsNlYA1VIq2VB {
		color: var(--theme-text-color-lighten);
		font-size: .24rem;
		line-height: .35rem
	}

	.C3g78JbIzYOGJevcEFJk .N0JQr4MRsNlYA1VIq2VB>p {
		margin-bottom: .01rem
	}

	.C3g78JbIzYOGJevcEFJk .N0JQr4MRsNlYA1VIq2VB .Q6J2UULA0fgYUpJzLVu6 {
		color: var(--theme-text-color-darken);
		font-weight: 700;
		margin-left: .05rem
	}

	.C3g78JbIzYOGJevcEFJk .N0JQr4MRsNlYA1VIq2VB .drePilqDntTa5TlrjiiD {
		font-size: .2rem;
		height: auto;
		max-width: 2.8rem;
		min-height: .5rem;
		min-width: 1.2rem
	}

	.C3g78JbIzYOGJevcEFJk .N0JQr4MRsNlYA1VIq2VB .drePilqDntTa5TlrjiiD>span {
		word-wrap: break-word;
		white-space: normal;
		word-break: break-word
	}

	.C3g78JbIzYOGJevcEFJk .DOVieBMgJWhGlNfqz8mX .Q6J2UULA0fgYUpJzLVu6 {
		color: var(--theme-secondary-color-finance)
	}
</style>
