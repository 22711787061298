<template>
	<div class="promote promoteUser">
		<van-nav-bar title="Dados" left-arrow fixed placeholder @click-left="onClickLeft" />
		<div class="vJuJgxthUQZJbZ2HYXHI">
			<div class="vPZG_rD0AXEtCxGfwk8J" style="padding: .2rem .2rem 0 .2rem;">
				<div class="user-icon_logo">
					<img :src="$store.state.config.user_avatar_image"  class="user-icon_logo"
						alt="">
					<!-- div class="vip">
						
						<span :data-text="userInfo.vip_info.level">{{userInfo.vip_info.level}}</span>
					</div> -->
				</div>
				<div class="rYKwPxzrGnAtGM50i1qg">
					<div class="oxAzHvfGt4BZ_a1aPzCg">Configurar</div>
					<div class="XZ3n0sbIklMdMiXMWBLg" @click="copyContent(userInfo.id)">
						ID: <span></span>
						{{userInfo.id}}
						<svg-icon className="yj5rHjAv0U7RlHMzWr7F" icon-class="style_1_icon_copy--svgSprite:all" />
					</div>
				</div>
			</div>
			<div class="vant-modify" @failed="updatePersonalData" >
				<van-form>
					<div style="padding: 0 .2rem;">
						<van-field v-model="userInfo.user_name" disabled style="margin-bottom: .5rem;">
							<svg-icon slot="left-icon"
								style="width: .25rem;height:.25rem;color: var(--theme-text-color-lighten);"
								icon-class="input_icon_zh--svgSprite:all" />
						</van-field>
						<van-field v-model="formData.wechat" style="margin-bottom: .5rem;" right-icon="close"
							:rules="[{ pattern:/.{6,}/, message: 'ID WeChat deve começar com letra ou sublinhado e ter de 6 a 20 letras, números, sublinhados e/ou hífens.' }]"
							placeholder="Introduza a ID do WeChat" @click-right-icon="formData.wechat=''">
							<svg-icon slot="left-icon"
								style="width: .32rem;height:.32rem;color: var(--theme-text-color-lighten);" classNameQita
								icon-class="icon-input_icon_wechat" />
						</van-field>
						<van-field v-model="formData.whatsapp" style="margin-bottom: .5rem;" right-icon="close"
							:rules="[{ pattern:/.{6,}/, message: 'Introduza 6-40 números' }]"
							placeholder="Insira o ID do WhatsApp" @click-right-icon="formData.whatsapp=''">
							<svg-icon slot="left-icon"
								style="width: .32rem;height:.32rem;color: var(--theme-text-color-lighten);" classNameQita
								icon-class="icon-input_icon_whatsapp" />
						
						</van-field>
						<van-field v-model="formData.facebook" style="margin-bottom: .5rem;" right-icon="close"
							:rules="[{ pattern:/.{3,}/, message: 'Por favor, insira 3-50 bits de inglês/números/caracteres' }]"
							placeholder="Insira o ID do Facebook" @click-right-icon="codeValue=''">
							<svg-icon slot="left-icon"
								style="width: .32rem;height:.32rem;color: var(--theme-text-color-lighten);" classNameQita
								icon-class="icon-input_icon_fb_Facebook_f_logo__2021_.svg" />
						</van-field>
						<van-field v-model="formData.telegram" style="margin-bottom: .5rem;" right-icon="close"
							:rules="[{ pattern:/.{5,}/, message: 'Introduza 5-40 letras/números/sublinhados' }]"
							placeholder="Insira o ID do telegram" @click-right-icon="codeValue=''">
							<svg-icon slot="left-icon"
								style="width: .32rem;height:.32rem;color: var(--theme-text-color-lighten);" classNameQita
								icon-class="icon-input_icon_telegram" />
							<!-- 	<svg id="icon_telegram"
							  <path id="Path_6358" 
							  <path id="Path_6359" 
							</svg> -->
						</van-field>
						<p class="TZQNFPYcnqoR0csegFxt">Selecione a data de nascimento (depois de definida, não pode ser
							modificada)</p>
							
						<div style="position: relative;">
							<van-field disabled v-model="formData.birthday"
								
								placeholder="Dia Mês Ano" >
								<svg-icon slot="right-icon"
									style="width: .22rem;;height:.22rem;;transform: rotate(-90deg);color: var(--theme-text-color-lighten);" 
									icon-class="comm_icon_fh--svgSprite:all" />
							</van-field>
							<div  @click="isShowNum=true" class="dianshi"></div>
						</div>
					</div>
				<div class="orkXHvtNM5R_5twq6l5I">
						<button type="button"
							@click="onClickLeft"
							class="ant-btn RRWQlUJLCPB59ggBUdFl">Retornar</button>
							<van-button @click="updatePersonalData"
							class="ant-btn ant-btn-primary yJrr5gS3ZL4NGzZkaPXH"  type="info" native-type="submit">Salvar</van-button>
							
					</div>

				</van-form>


			</div>
			<!-- <van-icon name="close" /> -->


		</div>
	
		<van-popup v-model="isShowNum" position="bottom">
			<div class="c64wvaNEagvzgTpzXHeB">
				<van-datetime-picker v-model="currentDate"
					@cancel="isShowNum=false"
					@confirm="formBirthday"
					cancel-button-text='Cancelar' title="Selecione a data"
					confirm-button-text="Confirmar" type="datehour" :min-date="minDate" :max-date="maxDate" />
			</div>
		</van-popup>
	</div>

</template>

<script>
	// ant-input-password
	// input_icon_fb.svg
	// input_icon_telegram.svg
	// input_icon_wechat.svg
	// updatePersonalData
	// input_icon_whatsapp.svg
	import antInputPassword from "@/components/ant-input-password/index.vue"
	import {
		mapState
	} from 'vuex';
	import {
		updatePersonalData
	} from "@/apis/modules/home.js"
	import { Toast } from 'vant';
	export default {
		computed: {
			...mapState(['popList', 'userInfo'])
		},
		components: {
			antInputPassword
		},
		data() {
			return {
				codeValue: '',
				minDate: new Date(1924, 0, 1),
				maxDate: new Date(),
				currentDate: new Date(),
				formData: {
					wechat: '',
					whatsapp: "",
					facebook: '',
					telegram: '',
					birthday: '',
				},
				isShowNum: false,
				inputFocus: false,
			};
		},
		created() {
			this.formData={
				...this.$store.state.userInfo
			}
		},
		methods: {
			formatDate(date) {
			  var year = date.getFullYear();
			  var month = String(date.getMonth() + 1).padStart(2, '0');
			  var day = String(date.getDate()).padStart(2, '0');
			  return year + '-' + month + '-' + day;
			},
			 updatePersonalData(){
				 updatePersonalData(this.formData||{}).then(({code,msg,data})=>{
					 Toast(msg) 
				 })
			 },
			// var today = new Date();
			// console.log(formatDate(today)); // 输出格式如：2023-03-28
			formBirthday(e){
				this.isShowNum=false;
				this.formData.birthday=this.formatDate(e);
			},
			onClickLeft() {
				this.$router.go(-1);
			}
		},
	}
</script>
<style>
	.vJuJgxthUQZJbZ2HYXHI .van-field__error-message {
		position: absolute;
		left: 0;
		font-size: .2rem !important;
		line-height: 1.2;
		margin-bottom: .1rem;
	}

	.vJuJgxthUQZJbZ2HYXHI .van-cell__value {
		position: initial;
	}

	.vJuJgxthUQZJbZ2HYXHI .van-cell {
		overflow: initial;
	}

	.vJuJgxthUQZJbZ2HYXHI .van-field--error .van-field__control {
		color: #c8c9cc !important;
	}

	.vJuJgxthUQZJbZ2HYXHI .van-field__error-message::before {
		background-color: var(--theme-secondary-color-error);
		content: "";
		height: 0.18rem;
		margin-right: 0.1rem;
		visibility: visible;
		width: 0.18rem;
		display: inline-block;
		background-image: url('./../../assets/5e8fdeaf-a95f-4566-adac-d84c9605a176.png');
		background-size: 13.068rem 12.753rem;
		border-radius: 50%;
		background-position: -12.888rem -3.825rem;
	}
	.dianshi{
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 99;
	}

	.c64wvaNEagvzgTpzXHeB .van-picker__title {
		color: var(--theme-text-color-darken);
		font-size: .3rem;
		line-height: normal;
	}

	.c64wvaNEagvzgTpzXHeB .van-picker__toolbar {
		border-bottom: thin solid var(--theme-color-line);
		height: auto;
		margin-bottom: .2rem;
		padding-bottom: .2rem
	}

	.c64wvaNEagvzgTpzXHeB .van-picker-column__item.van-picker-column__item--selected {
		color: var(--theme-text-color-darken) !important
	}

	.promoteUser .van-picker__mask,
	.promoteUser .van-popup {
		background: none !important;
	}

	.promoteUser .van-picker-column__item {
		color: var(--theme-text-color-lighten) !important
	}

	.c64wvaNEagvzgTpzXHeB {
		background-color: var(--theme-main-bg-color);
		border-top-left-radius: .14rem;
		border-top-right-radius: .14rem;
		position: relative;
		z-index: -1;
	}

	.c64wvaNEagvzgTpzXHeB .van-picker-column {
		position: relative;
	}

	.c64wvaNEagvzgTpzXHeB>div {
		width: 100% !important
	}

	.promoteUser .van-picker {
		background: none !important;
	}

	.c64wvaNEagvzgTpzXHeB .van-picker.van-datetime-picker {
		padding: .2rem 0
	}

	.c64wvaNEagvzgTpzXHeB .van-picker__toolbar {
		border-bottom: thin solid var(--theme-color-line);
		height: auto;
		margin-bottom: .2rem;
		padding-bottom: .2rem
	}

	.c64wvaNEagvzgTpzXHeB .van-picker__title {
		color: var(--theme-text-color-darken);
		font-size: .3rem;
		line-height: normal
	}

	.c64wvaNEagvzgTpzXHeB .van-picker__cancel {
		color: var(--theme-text-color-lighten);
		font-size: .3rem
	}

	.c64wvaNEagvzgTpzXHeB .van-picker__confirm {
		color: var(--theme-primary-color);
		font-size: .3rem
	}

	.c64wvaNEagvzgTpzXHeB .van-picker__frame {
		background-color: var(--theme-bg-color);
		left: 0;
		right: 0;
		z-index: -1
	}

	.c64wvaNEagvzgTpzXHeB .van-picker__frame:after {
		display: none
	}

	.c64wvaNEagvzgTpzXHeB .van-picker-column__item.van-picker-column__item--selected {
		font-size: .3rem
	}

	.c64wvaNEagvzgTpzXHeB .van-picker-column__item.van-picker-column__item--selected+li {
		font-size: .24rem
	}

	.c64wvaNEagvzgTpzXHeB .van-picker-column__item {
		font-family: MicrosoftYaHeiLobby;
		font-size: .22rem
	}

	.c64wvaNEagvzgTpzXHeB .van-picker-column__item[\:has\(\%2B\.van-picker-column__item--selected\)] {
		font-size: .24rem
	}

	.c64wvaNEagvzgTpzXHeB .van-picker-column__item:has(+.van-picker-column__item--selected) {
		font-size: .24rem
	}

	.c64wvaNEagvzgTpzXHeB .van-picker-column__item>div {
		font-size: inherit
	}
</style>
<style scoped>
	 .orkXHvtNM5R_5twq6l5I {
	    background-color: var(--theme-main-bg-color);
	    bottom: 0;
	    -webkit-box-shadow: 0 -.03rem .1rem 0 rgba(0,0,0,.1);
	    box-shadow: 0 -.03rem .1rem 0 rgba(0,0,0,.1);
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex: none;
	    flex: none;
	    height: 1.3rem;
	    position: fixed;
	    z-index: 2
	}
	.orkXHvtNM5R_5twq6l5I>button {
	    margin: .2rem auto auto
	}
	 .orkXHvtNM5R_5twq6l5I>button:first-child {
	    margin-left: .2rem;
	    margin-right: .1rem
	}
	.orkXHvtNM5R_5twq6l5I>button:last-child {
	    margin-left: .1rem;
	    margin-right: .2rem
	}
	.orkXHvtNM5R_5twq6l5I .RRWQlUJLCPB59ggBUdFl {
	    background-color: var(--theme-main-bg-color);
	    border: thin solid var(--theme-primary-color);
	    color: var(--theme-primary-color)!important;
	    color: var(--theme-primary-font-color);
	    font-size: .24rem;
	    height: .7rem;
	    line-height: .55rem;
	    margin-right: 0;
	    text-align: center;
	    width: 3.45rem
	}
	 .orkXHvtNM5R_5twq6l5I .yJrr5gS3ZL4NGzZkaPXH {
	    color: var(--theme-primary-font-color);
	    font-size: .24rem;
	    height: .7rem;
	    line-height: .55rem;
	    text-align: center;
	    width: 3.45rem
	}
	.ant-btn.ant-btn-primary
	 {
		    background-color: var(--theme-primary-color);
		    border-color: var(--theme-primary-color)
	}
	.vJuJgxthUQZJbZ2HYXHI {
		/* padding: .2rem; */
	}

	.vPZG_rD0AXEtCxGfwk8J {
		display: flex;
		align-items: center;
		margin-bottom: .5rem;
	}

	.user-icon_logo {
		border-radius: .14rem;
		height: 1.1rem;
		position: relative;
		width: 1.1rem;
		position: relative;
	}

	.vip {
		height: .24rem;
		max-width: .66rem;
		display: flex;
		align-items: center;
		min-width: .5rem;
		bottom: 0;
		position: absolute;
		background: rgb(36, 178, 153);
		right: 0;
		padding: 0 .035rem;
	}

	.vip img {
		height: .172rem;
	}

	.vip span {
		background: transparent none repeat 0 0 / auto auto padding-box border-box scroll;
		background: initial;
		font-size: .22rem;
		font-style: italic;
		margin: -.025rem 0 0 .01rem;
		-webkit-transform: scale(.9);
		transform: scale(.9);
		color: transparent;
		display: inline-block;
		font-style: normal;
		font-weight: 700;
		margin: -.02rem 0 0;
		position: relative;
		text-shadow: 0 .01rem 0 rgba(0, 0, 0, .4);
	}

	.vip span::before {
		-webkit-background-clip: text;
		background-clip: text;
		background-image: -webkit-gradient(linear, left top, left bottom, from(#f7ea94), color-stop(51%, #e5b952), to(#ce9510));
		background-image: linear-gradient(180deg, #f7ea94 0, #e5b952 51%, #ce9510);
		background-size: cover;
		content: attr(data-text);
		height: 100%;
		left: 0;
		position: absolute;
		text-shadow: none;
		top: 0;
		width: 100%;
		z-index: 0;
	}

	.rYKwPxzrGnAtGM50i1qg {
		-ms-flex-align: start;
		-ms-flex-pack: center;
		align-items: flex-start;
		display: -ms-flexbox;
		display: flex;
		font-size: .22rem;
		-ms-flex-direction: column;
		flex-direction: column;
		justify-content: center;
		margin-left: .4rem
	}

	.rYKwPxzrGnAtGM50i1qg .oxAzHvfGt4BZ_a1aPzCg {
		color: var(--theme-primary-color);
		cursor: pointer;
	}

	.rYKwPxzrGnAtGM50i1qg .XZ3n0sbIklMdMiXMWBLg {
		color: var(--theme-text-color-lighten);
	}

	.rYKwPxzrGnAtGM50i1qg .Hk8saLgKB74iw1F2PR56 {
		margin-left: .02rem;
	}

	.yj5rHjAv0U7RlHMzWr7F {
		width: .3rem;
		color: var(--theme-primary-color);
		height: .3rem;
	}

	.TZQNFPYcnqoR0csegFxt {
		font-size: .2rem;
		color: var(--theme-text-color-darken);
		margin-bottom: .2rem;

	}
</style>
