<template>
	<div class="PUxRK2xxVs7RWk3be3YQ">
		<div class="NclrsSEy6ZpXXLyewIFT theme-box-shadow-h5 bg-transparent">
			<div class="y48JsVOpX304ahINP73C">
				<div class="JruHyobZCqPMne3KlMqm"><img 
						src="./../../assets/icons/images/img_kf_kf01.webp"
						alt="." data-blur="0" data-status="loaded"
						class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
						style="width: 0.82rem; height: 0.82rem;">
					<div class="AMLeUMbelS6ivFZHGjaN">
						<!-- 
						_PerguntasFreqüentes: "Perguntas Freqüentes",
		_Apoioonline:"Apoio online",
		_Contactaragora:"Contactar agora",
		_Conversecomoserv:"Converse com o serviço profissional de apoio ao cliente online para resolver os seus problemas",
						-->
						<!-- _customerservice -->
						<!--  -->
						<div class="rJ0HxNLy7hMD6zMzi7Bs">{{ $t('notice._Apoioonline') }} 24/7</div>
						
						<div class="D_q8JgriUsq7s4EzidZM">{{ $t('notice._Conversecomoserv') }}</div>
					</div>
				</div>
				<div class="UriY3ojAVELhxQrK67xY" @click="openUrl(config.online_service)"><button type="button"
						class="ant-btn ant-btn-link dz9zVj2lA_iSnT6r5R3F"><span>{{ $t('notice._Apoioonline') }} 24/7</span></button></div>
			</div>

		</div>
		<div class="zPyu_YtZsGGcci6Iw6kg bg-transparent">
			<van-tabs v-model="activeName" @click="routerFun" :lineWidth="(activeLength*6+24+'px')" animated
				line-height='1px' class="th" background="var(--theme-main-bg-color)" :ellipsis="false"
				title-active-color="var(--theme-primary-color)" title-inactive-color="var(--theme-text-color-darken)"
				color="var(--theme-primary-color)">
				<van-tab title-style="font-size: 0.22rem;" v-for="(item,index) in tabList" :name='index' :key="index">
					<!-- <component :is="item.content"></component> -->
					<template #title>
						<div class="tab-tie">
							<img :src="item.icon" style="width: .35rem;height:.35rem;margin-right: .1rem;" alt="">
							{{$t(item.name)}}
						</div>
					</template>
					<div class="jF0NZSjxkCfI4R8LE91s">
						<div class="pSWTmGTLbIpGjSELwrSw">
							<div class="qHlGCvAvQcUcfenkHUuv">
								<div class="HUFPnddN6QRItET8dLXV theme-box-shadow">
									<img class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton" :src="item.icon"
										style="width: 0.7rem; height: 0.7rem;">
									<div class="hxFqHSEj9lpws2DMLEqM">
										<p class="GKOydpVoe7GvnNKV_wBu">TG Serviço</p><span
											class="FNY2dPfvxeze61Tum3oU">{{item.name=='notice._TelegramSuporte'?config.telegram:''}}</span>
											<!-- config -->
											 <!-- config.telegram_group -->
											  <!-- @click="" -->
									</div><span class="PmwtECGo9wfGBMP0uy71 copyIcon"><button  @click="openUelLis(item.name=='notice._TelegramSuporte'?config.telegram:config.facebook)" type="button"
											class="ant-btn ant-btn-primary"><span>{{ $t('notice._Contactaragora') }} </span></button></span>
								</div>
							</div>

						</div>

					</div>
				</van-tab>
			</van-tabs>
		</div>

		<div class="p0aK2FptVKRqNDg8vMi9 bg-transparent">
			<van-tabs v-model="active" @click="routerLength" :lineWidth="(activeLen*6+'px')" animated line-height='1px'
				class="th" background="var(--theme-main-bg-color)" :ellipsis="false"
				title-active-color="var(--theme-primary-color)" title-inactive-color="var(--theme-text-color-darken)"
				color="var(--theme-primary-color)">
				<van-tab title-style="font-size: 0.22rem;" v-for="(item_1,index_1) in tbaList" :title="$t(item_1.name)"
					:name='index_1' :key="index_1">
					<div class="vbqN4_2wIIgxJy8CxfPB" style="height: 5.7rem;">
						<div v-if="active==1 && item.questionList.length"   v-for="(item,index) in comste_Kefy" >
							<ul class="MfNaOzkYuvIyTP4UPv3s" >
								<li v-for="(item_,index_) in item.questionList" 
								@click="$router.push({name:'details',query:{
									title:item_.title,
									isConter:true,
									content:item_.content
								}})"
								>
									<div>{{index+1}}、{{item_.title}}</div>
									<svg-icon className="imageWrap" icon-class="comm_icon_fh--svgSprite:all" />
								</li>
							</ul>
						</div>
						<div v-for="(item,index) in comste_Kefy" v-if="item.questionList.length && active==0">
							<div class="PBm9oAVtTatTjRQ7_8e1"   @click="comsteKefyItemIs(index,item)">
								<div class="zDkUTpZxO2yy64VQCzH1"><img
										:src="item.typeIcon" 
										alt="."
										class="goDRiiBsuEuXD3W1NphN GgAalyCT_nMrUn3ge8Q8 use-skeleton"
										style="width: 0.6rem; height: 0.6rem;">
									<div>
										<p>{{item.typeName}}</p>
									</div>
								</div>
								<svg-icon className="imageWrap" icon-class="comm_icon_fh--svgSprite:all" />
							</div>
							<ul class="MfNaOzkYuvIyTP4UPv3s" v-if="item.isShow" >
								<li v-for="(item_,index_) in item.questionList" 
								@click="$router.push({name:'details',query:{
									title:item_.title,
									isConter:true,
									content:item_.content
								}})"
								>
									<div>{{index_+1}}、{{item_.title}}</div>
									<svg-icon className="imageWrap" icon-class="comm_icon_fh--svgSprite:all" />
								</li>
							</ul>
						</div>
					</div>
				</van-tab>
			</van-tabs>
		</div>
	</div>
</template>

<script>
	import Suporte from "./Suporte.vue"
	import {comsteKefy} from "./js/keJSon.js"
	import {Freqentes} from "./js/s.js"
	import { mapState } from 'vuex';
	// config
	export default {
		components: {},
		computed:{
			...mapState(['config','userInfo'])
		},
		data() {
			return {
				activeName: 0,
				activeLength: 16,
				active: 0,
				activeLen: 3,
				comste_Kefy:[...comsteKefy],
				Freq_entes:[...Freqentes],
				tabList: [

					{
						name: 'notice._TelegramSuporte',
						icon: require('./../../assets/icons/images/icon_im_telegram.webp')
					},
					{
						name: 'notice._FacebookSuporte',
						icon: require('./../../assets/icons/images/icon_im_Facebook.webp'),
					},
				],
				tbaList: [{
						name: 'notice._FAQ',
						content:<template>
									<div>ssss</div>
						</template>
					},
					{
						name: 'notice._PerguntasFreqüentes',
					},
				]
			}
		},
		methods: {
			openUrl(e) {
				if (e && e != '#') {
					if(/^http/.test(e)){
						window.open(e, '_blank');
						return false;
					}
					this.$router.push({name:e})
				}
			},
			openUelLis(e){
				if (e && e != '#') {
					if(/^http/.test(e)){
						window.open(e, '_blank');
						return false;
					}
					this.$router.push({name:e})
				}
			},
			tabFun(e) {
				this.$store.commit('active', e)
			},
			comsteKefyItemIs(index,item){
				// alert(1)
				this.comste_Kefy[index].isShow=!item.isShow;
				this.comste_Kefy=[...this.comste_Kefy];
				console.log(this.comste_Kefy[index].isShow)
			},
			routerFun(e, l) {
				this.activeLength = l.length;
			},
			routerLength(e, l) {
				this.activeLen = l.length;
			},
		}
	}
</script>
<style>
	.th .van-tab__pane {
		height: 100% !important;
	}

	.th .van-tab {
		flex: none;
	}
</style>
<style scoped>
	.MfNaOzkYuvIyTP4UPv3s li {
	    -ms-flex-align: center;
	    -ms-flex-pack: justify;
	    align-items: center;
	    border-top: thin solid var(--theme-color-line);
	    cursor: pointer;
	    display: -ms-flexbox;
	    display: flex;
	    font-size: .2rem;
	    justify-content: space-between;
	    padding: .2rem 0
	}
	
	.MfNaOzkYuvIyTP4UPv3s li>div {
	    color: var(--theme-text-color-darken)
	}
	
	.MfNaOzkYuvIyTP4UPv3s li svg {
	    color: var(--theme-color-line);
	    -webkit-transform: rotate(-180deg);
	    transform: rotate(-180deg);
		width: .2rem;
		height: .2rem;
	}
	
	.MfNaOzkYuvIyTP4UPv3s.d5KxNdIECOjxvAVTZnrA {
	    padding: 0 .2rem
	}
	
	.MfNaOzkYuvIyTP4UPv3s.d5KxNdIECOjxvAVTZnrA li:first-of-type {
	    border-top: 0
	}

	.PUxRK2xxVs7RWk3be3YQ .zPyu_YtZsGGcci6Iw6kg .jF0NZSjxkCfI4R8LE91s {
		margin: .2rem 0
	}

	.PUxRK2xxVs7RWk3be3YQ .zPyu_YtZsGGcci6Iw6kg .pSWTmGTLbIpGjSELwrSw .HUFPnddN6QRItET8dLXV:nth-child(2n-1) {
		background-color: var(--theme-bg-color)
	}

	.PUxRK2xxVs7RWk3be3YQ .NclrsSEy6ZpXXLyewIFT {
		background-color: var(--theme-main-bg-color);
		border-radius: 0 0 .1rem .1rem;
		-webkit-box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		padding: .2rem
	}

	.tab-tie {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.PUxRK2xxVs7RWk3be3YQ .y48JsVOpX304ahINP73C {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		justify-content: space-between
	}

	html .ant-btn.ant-btn-primary,
	html .ant-btn.ant-btn-primary.active,
	html .ant-btn.ant-btn-primary:active,
	html .ant-btn.ant-btn-primary:focus,
	html .ant-btn.ant-btn-primary:hover {
		background-color: var(--theme-primary-color);
		border-color: var(--theme-primary-color);
	}

	html[data-device=mobile] .PUxRK2xxVs7RWk3be3YQ .zPyu_YtZsGGcci6Iw6kg .pSWTmGTLbIpGjSELwrSw .HUFPnddN6QRItET8dLXV button {
		font-size: .2rem;
		height: .5rem;
		line-height: .24rem;
		width: 1.2rem
	}

	.PBm9oAVtTatTjRQ7_8e1,
	.zDkUTpZxO2yy64VQCzH1 {
		display: flex;
		align-items: center;
	}

	.PBm9oAVtTatTjRQ7_8e1 {
		justify-content: space-between;
		border-bottom: thin solid var(--theme-color-line);
	}

	.PBm9oAVtTatTjRQ7_8e1 .imageWrap {
		color: var(--theme-color-line);
		font-size: .2rem;
		width: .2rem;
		height: .2rem;
		-webkit-transform: rotate(-90deg);
		transform: rotate(-90deg);
	}

	.zDkUTpZxO2yy64VQCzH1 {
		margin: .24rem 0;
	}

	.PUxRK2xxVs7RWk3be3YQ .zPyu_YtZsGGcci6Iw6kg .pSWTmGTLbIpGjSELwrSw .HUFPnddN6QRItET8dLXV button {
		font-size: .2rem !important;
		height: .5rem !important;
		line-height: .24rem !important;
		width: 1.2rem !important;
	}

	.ant-btn {
		background-color: #fff;
		background-image: none;
		border: 1px solid #d9d9d9;
		border-radius: 4px;
		-webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, .015);
		box-shadow: 0 2px 0 rgba(0, 0, 0, .015);
		color: rgba(0, 0, 0, .65);
		cursor: pointer;
		display: inline-block;
		font-size: 14px;
		font-weight: 400;
		height: 32px;
		line-height: 1.499;
		padding: 0 15px;
		position: relative;
		text-align: center;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
		-webkit-transition: all .3s cubic-bezier(.645, .045, .355, 1);
		transition: all .3s cubic-bezier(.645, .045, .355, 1);
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		white-space: nowrap;
	}

	.PUxRK2xxVs7RWk3be3YQ .NclrsSEy6ZpXXLyewIFT .y48JsVOpX304ahINP73C .JruHyobZCqPMne3KlMqm {
		-ms-flex-align: initial;
		align-items: normal
	}

	.PUxRK2xxVs7RWk3be3YQ .NclrsSEy6ZpXXLyewIFT {
		background-color: var(--theme-main-bg-color);
		border-radius: 0 0 .1rem .1rem;
		-webkit-box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		padding: .2rem
	}

	.PUxRK2xxVs7RWk3be3YQ .y48JsVOpX304ahINP73C {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		justify-content: space-between
	}

	.PUxRK2xxVs7RWk3be3YQ .y48JsVOpX304ahINP73C .JruHyobZCqPMne3KlMqm {
		-ms-flex-align: center;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex: 1;
		flex: 1
	}

	.PUxRK2xxVs7RWk3be3YQ .y48JsVOpX304ahINP73C .JruHyobZCqPMne3KlMqm .AMLeUMbelS6ivFZHGjaN {
		margin-left: .2rem
	}

	.PUxRK2xxVs7RWk3be3YQ .y48JsVOpX304ahINP73C .JruHyobZCqPMne3KlMqm .rJ0HxNLy7hMD6zMzi7Bs {
		color: var(--theme-text-color-darken);
		font-size: .22rem
	}

	.PUxRK2xxVs7RWk3be3YQ .y48JsVOpX304ahINP73C .JruHyobZCqPMne3KlMqm .D_q8JgriUsq7s4EzidZM {
		color: var(--theme-text-color-lighten);
		font-size: .18rem;
		margin-top: .05rem
	}

	.vbqN4_2wIIgxJy8CxfPB {
		padding: 0 .2rem;
	}

	.zDkUTpZxO2yy64VQCzH1 div p {
		font-size: .24rem;
		margin-left: .2rem;
		color: var(--theme-text-color-darken);
	}

	.PUxRK2xxVs7RWk3be3YQ .kGcNZa2qms4J00AcVsyU {
		-ms-flex-align: center;
		-ms-flex-pack: end;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		justify-content: flex-end;
		width: 3.4rem
	}

	.PUxRK2xxVs7RWk3be3YQ .kGcNZa2qms4J00AcVsyU.wATxh4tJIIBWzWLFvZDj {
		-ms-flex-pack: start;
		justify-content: flex-start
	}

	.PUxRK2xxVs7RWk3be3YQ .kGcNZa2qms4J00AcVsyU .dz9zVj2lA_iSnT6r5R3F {
		width: 1rem
	}

	.PUxRK2xxVs7RWk3be3YQ .kGcNZa2qms4J00AcVsyU .dz9zVj2lA_iSnT6r5R3F:nth-of-type(3n+1) {
		margin-left: 0
	}

	.PUxRK2xxVs7RWk3be3YQ .kGcNZa2qms4J00AcVsyU .dz9zVj2lA_iSnT6r5R3F:nth-of-type(n+4) {
		margin-top: .2rem
	}

	.PUxRK2xxVs7RWk3be3YQ .dz9zVj2lA_iSnT6r5R3F {
		border: thin solid var(--theme-ant-primary-color-0);
		font-size: .16rem;
		height: .4rem;
		line-height: .18rem;
		margin-left: .2rem;
		text-align: center;
		width: 1.2rem
	}

	.PUxRK2xxVs7RWk3be3YQ .dz9zVj2lA_iSnT6r5R3F span {
		word-wrap: break-word;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		vertical-align: middle;
		white-space: pre-wrap
	}

	.PUxRK2xxVs7RWk3be3YQ .zPyu_YtZsGGcci6Iw6kg {
		background-color: var(--theme-main-bg-color);
		border-radius: .1rem;
		-webkit-box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		margin-top: .2rem;
		padding: 0
	}

	.PUxRK2xxVs7RWk3be3YQ .zPyu_YtZsGGcci6Iw6kg .Vp8_vOrXLQ080DAdQlmJ .common-tab-item {
		-ms-flex: 0 0 auto;
		flex: 0 0 auto
	}

	.PUxRK2xxVs7RWk3be3YQ .zPyu_YtZsGGcci6Iw6kg .Vp8_vOrXLQ080DAdQlmJ .common-tab-item img {
		margin-right: .05rem
	}

	.PUxRK2xxVs7RWk3be3YQ .zPyu_YtZsGGcci6Iw6kg .jF0NZSjxkCfI4R8LE91s {
		margin: .2rem 0
	}

	.PUxRK2xxVs7RWk3be3YQ .zPyu_YtZsGGcci6Iw6kg .pSWTmGTLbIpGjSELwrSw {
		height: 100%;
		position: relative;
		width: 100%
	}

	.PUxRK2xxVs7RWk3be3YQ .zPyu_YtZsGGcci6Iw6kg .pSWTmGTLbIpGjSELwrSw .qHlGCvAvQcUcfenkHUuv {
		-ms-flex-align: center;
		-ms-flex-pack: justify;
		align-items: center;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 0 .2rem
	}

	.PUxRK2xxVs7RWk3be3YQ .zPyu_YtZsGGcci6Iw6kg .pSWTmGTLbIpGjSELwrSw .qHlGCvAvQcUcfenkHUuv .HUFPnddN6QRItET8dLXV {
		-ms-flex-align: center;
		align-items: center;
		border: thin solid var(--theme-color-line);
		border-radius: .1rem;
		-webkit-box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: flex;
		font-size: .14rem;
		height: 1rem;
		padding: .2rem .3rem;
		width: 100%
	}

	.PUxRK2xxVs7RWk3be3YQ .zPyu_YtZsGGcci6Iw6kg .pSWTmGTLbIpGjSELwrSw .qHlGCvAvQcUcfenkHUuv .HUFPnddN6QRItET8dLXV .hxFqHSEj9lpws2DMLEqM {
		-ms-flex: 1;
		flex: 1;
		margin: 0 .3rem;
		min-width: 1.5rem
	}

	.PUxRK2xxVs7RWk3be3YQ .zPyu_YtZsGGcci6Iw6kg .pSWTmGTLbIpGjSELwrSw .qHlGCvAvQcUcfenkHUuv .HUFPnddN6QRItET8dLXV .hxFqHSEj9lpws2DMLEqM .GKOydpVoe7GvnNKV_wBu {
		color: var(--theme-text-color-lighten);
		font-size: .18rem;
		margin: 0;
		overflow: hidden;
		padding-bottom: 0;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%
	}

	.PUxRK2xxVs7RWk3be3YQ .zPyu_YtZsGGcci6Iw6kg .pSWTmGTLbIpGjSELwrSw .qHlGCvAvQcUcfenkHUuv .HUFPnddN6QRItET8dLXV .hxFqHSEj9lpws2DMLEqM .FNY2dPfvxeze61Tum3oU {
		color: var(--theme-text-color-darken);
		display: block;
		-ms-flex: 1;
		flex: 1;
		font-size: .16rem;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap
	}

	.PUxRK2xxVs7RWk3be3YQ .zPyu_YtZsGGcci6Iw6kg .pSWTmGTLbIpGjSELwrSw .qHlGCvAvQcUcfenkHUuv .HUFPnddN6QRItET8dLXV button {
		border-radius: .1rem;
		font-size: .16rem;
		height: .4rem;
		line-height: .18rem;
		padding: 0 .06rem;
		width: 1rem
	}

	.PUxRK2xxVs7RWk3be3YQ .zPyu_YtZsGGcci6Iw6kg .pSWTmGTLbIpGjSELwrSw .qHlGCvAvQcUcfenkHUuv .HUFPnddN6QRItET8dLXV button>span {
		word-wrap: break-word;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		vertical-align: middle;
		white-space: pre-wrap
	}

	.PUxRK2xxVs7RWk3be3YQ .p0aK2FptVKRqNDg8vMi9 {
		background-color: var(--theme-main-bg-color);
		border-radius: .1rem;
		-webkit-box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		margin-top: .2rem;
		padding: 0
	}

	.PUxRK2xxVs7RWk3be3YQ .UriY3ojAVELhxQrK67xY {
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-left: 1.02rem;
		margin-top: .2rem;
		width: 100%
	}

	.PUxRK2xxVs7RWk3be3YQ .UriY3ojAVELhxQrK67xY .dz9zVj2lA_iSnT6r5R3F {
		font-size: .2rem;
		height: .5rem;
		line-height: .22rem;
		margin-left: .2rem;
		width: 1.75rem;
		border-radius: .14rem;
		background-color: transparent !important;
		color: var(--theme-ant-primary-color-0) !important;
	}

	.PUxRK2xxVs7RWk3be3YQ .dz9zVj2lA_iSnT6r5R3F span {
		word-wrap: break-word;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		vertical-align: middle;
		white-space: pre-wrap
	}


	.PUxRK2xxVs7RWk3be3YQ .zPyu_YtZsGGcci6Iw6kg {
		background-color: var(--theme-main-bg-color);
		border-radius: .1rem;
		-webkit-box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		box-shadow: 0 .03rem .09rem 0 rgba(0, 0, 0, .06);
		margin-top: .2rem;
		padding: 0
	}
</style>
