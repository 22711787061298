<template>
	<div  style="width: 100%;height: 100vh;position: relative;">
		<div class="LIj05jKUpegPgDeMG5EV" @click="onClickLeft">
			<svg-icon className="imageWrap"
				style="width: .4rem;height: .4rem;"
				icon-class="game_2_style_1_zk--svgSprite:all" />
			<span>Lobby</span>
		</div>
		<iframe style="width: 100%;height: 100vh;" :src="iframeData" frameborder="0"></iframe>
	</div>
</template>

<script>
	import {startDemoGame,startGame} from "@/apis/modules/game.js"
	import { Toast } from 'vant';
	export default{
		data() {
			return {
				iframeData:"",
			}
		},
		created() {
			let {id,category}=this.$route.query;
			if(category=='demo' || category == 'Demo'){
				this.startDemoGame(id);
			}else{
				this.startGame(id);
			}
		},
		methods:{
			startDemoGame(id){
				startDemoGame({
					game_id:id,
				}).then(({code,data={},msg})=>{
					if(code==1){
						Toast.fail({
							message:msg,
							duration:1000
						});
					}
					this.iframeData=data;
				})
			},
			onClickLeft(){
					 this.$router.go(-1);
			},
			startGame(id){
				startGame({
					game_id:id,
				}).then(({code,data={},msg})=>{
					if(code==1){
						Toast.fail({
							message:msg,
							duration:1000
						});
					}
					this.iframeData=data;
				})
			}
		}
	}
</script>
<style>
	.van-toast .van-icon-fail{
		margin-right: .1rem !important;
	}
</style>
<style scoped>
	.LIj05jKUpegPgDeMG5EV {
	    -ms-flex-align: center;
	    -ms-flex-pack: center;
	    -webkit-user-drag: none;
	    align-items: center;
	    background-color: rgba(0,0,0,.6);
	    border: .015rem solid hsla(0,0%,100%,.65);
	    border-radius: 50%;
	    -webkit-box-shadow: 0 .03rem .06rem 0 rgba(0,0,0,.16);
	    box-shadow: 0 .03rem .06rem 0 rgba(0,0,0,.16);
	    color: #fff;
	    cursor: pointer;
	    display: -ms-flexbox;
	    display: flex;
	    -ms-flex-direction: column;
	    flex-direction: column;
	    height: .95rem;
	    justify-content: center;
	    left: .03rem;
	    position: fixed;
	    top: .03rem;
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
	    width: .95rem;
	    z-index: 1998
	}
	.LIj05jKUpegPgDeMG5EV span{
		    font-size: .2rem;
	}
</style>