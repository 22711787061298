<template>
	<div class="promote SenhadeLogin ">
		<van-nav-bar :title="$t('security._SenhadeSaque')" left-arrow fixed placeholder @click-left="onClickLeft" />
		<div class="I9T4OGduED94X6ahO3Qj" v-if="!old_password">
			<div class="DRcvr_ui3dMkJZpnft5Z" v-if="!userInfo.set_withdraw_password">{{ $t('security._Paraase') }}</div>
			<div class="Xq4eL9kY4HGkgyNzZTx4" v-if="!userInfo.set_withdraw_password">{{ $t('security._Definasuasenhadesaque') }}</div>
			<div class="Xq4eL9kY4HGkgyNzZTx4" v-if="userInfo.set_withdraw_password">Alterar Senha de Saque</div>
			<!--  -->
			<div class="Ou1aviPriFX421w97dGA">
				<span>{{ $t('security._NovaSenhadeSaque') }}</span>
				<i @click="isShowNum=!isShowNum" :class="{
						isShowNum:isShowNum
					}">
					<svg-icon className="ZaAWGnvFuhPwlBk40od_"
						:icon-class="`${isShowNum?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'}`" />
				</i>
			</div>
			<div class="conterArr">
				<ant-input-password :isShow="isShowNum"  @change="newPassword" style="margin-bottom: .5rem;"></ant-input-password>
				<div v-if="isShowPasword" class="ant-form-explain">{{ $t('security._Nãomesmosounúmeroscontínuos') }}</div>
			</div>
			<div class="Ou1aviPriFX421w97dGA">
				<span>{{ $t('security._Confirmenova') }}</span>
				<i @click="isShowNumConfirm=!isShowNumConfirm" :class="{
						isShowNumConfirm:isShowNumConfirm
					}">
					<svg-icon className="ZaAWGnvFuhPwlBk40od_"
						:icon-class="`${isShowNumConfirm?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'}`" />
				</i>
			</div>
			<div class="conterArr">
				<ant-input-password style="margin-bottom: .5rem;"   @change="new_passwordConfirm"  :isShow='isShowNumConfirm'></ant-input-password>
				<div v-if="isShowNumConfirm" class="ant-form-explain">{{ $t('security._Nãomesmosounúmeroscontínuos') }}</div>
				<div v-else-if="consistent" class="ant-form-explain">{{ $t('security._Senhaincorreta') }}</div>
			</div>
			<div class="FoD6bH3V5A47RnLkco6A">
				{{ $t('security._Atenção') }}
			</div>
		</div>
		<div class="I9T4OGduED94X6ahO3Qj login-register-from-space" v-if="old_password">
			<div class="Xq4eL9kY4HGkgyNzZTx4 " v-if="userInfo.set_withdraw_password">Verificar a senha para login</div>
			<div class="" style="margin-bottom: .2rem;">
				<antInput
					left-icon="a" :modelValue="password"
						@focus="onFocus"
						@blur="onBlur"
						@onInput="onInput"
						maxlength="16"
						form-key='password'
						:type="passwordType"
						placeholder="Por favor, insira a nova senha" :class="{
					 	'input-active':isFocus
					 }"
				>
					<div slot="left" style="display: flex;align-items: center;">
						<svg-icon className="imageWrap"
							style="width: .26rem;height: .26rem;color: var(--theme-text-color-lighten);"
							icon-class="input_icon_mm--svgSprite:all" />
					</div>
					<!-- fonData.password.type -->
					<div slot="right-icon" @click="showHide('password')">
						<svg-icon className="right-icon"   
							style="width: .26rem;height: .26rem;color: var(--theme-text-color-lighten);"
							:icon-class="passwordType=='text'?'comm_icon_show--svgSprite:all':'comm_icon_hide--svgSprite:all'" />
					</div>
				</antInput>
			</div>
			<div class="ant-form-explain" v-if="passwordTypeValue">{{passwordTypeValue}}</div>
			<div style="    color: var(--theme-primary-color);font-size: .22rem;text-align: right;">
				Outros Métodos de Verificação
			</div>
		</div>
		<div @click="withdrawPassword" class="Xhr_XJt3jLTtAL8pC16D verification-btn__group wg-fixed-no-desktop"><button type="button"
				class="ant-btn ant-btn-primary ant-btn-block verification-btn__next"
				>
				{{!userInfo.set_withdraw_password || old_password ?$t('security._Confirmar'): 'Próximo' }}
				</button>
		</div>
	</div>

</template>

<script>
	function checkPasswordStrength(password) {
	  let score = 0;
	  if (password.length > 6) score++;
	  if (password.length > 0) score++;
	  if(password.length >= 6){
		  if (password.match(/[A-Z]/)) score++; // 大写字母
		  if (password.match(/[a-z]/)) score++; // 小写字母
		  if (password.match(/[0-9]/)) score++; // 数字
		  if (password.match(/[\W_]/)) score++; // 特殊字符
	  }
	  if (password.length > 10) score++;
	  switch (score) {
	    case 0:
	    case 1:
	    case 2:
	    case 3:
	    case 4:
	      return 1; // 弱
	    case 5:
	      return 2; // 中等
		case 6:
			return 3; // 强
	    case 7:
		default:
		  return 4; // 非常强
	  }
	}
	function validatePassword(password) {
	    if (password.length < 6) {
	            return false; // 密码长度必须为6位
	        }
	        // 定义正则表达式来检查不同的字符类型
	        const hasDigit = /\d/.test(password);
	        const hasLower = /[a-z]/.test(password);
	        const hasUpper = /[A-Z]/.test(password);
	        const hasSpecial = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(password); // 定义一些常见的特殊字符
	        // 计算不同类型的字符数（这里实际上只关心是否存在，所以是布尔值）
	        let typeCount = 0;
	        if (hasDigit) typeCount++;
	        if (hasLower) typeCount++;
	        if (hasUpper) typeCount++;
	        if (hasSpecial) typeCount++;
	        // 检查是否至少有两种字符类型
	        return typeCount >= 2;
	}
	// ant-input-password
	import antInputPassword from "@/components/ant-input-password/index.vue"
	import {withdrawPassword} from "@/apis/modules/login.js"
	import { Toast } from 'vant';
	import { mapState } from 'vuex';
	import antInput from "@/components/logoin/input.vue"
	// withdrawPassword
	export default {
		components: {
			antInputPassword,
			antInput
		},
		computed:{
			...mapState(['popList','userInfo','moneyInfo'])
		},
		data() {
			return {
				codeValue: '',
				isShowNum: false,
				isShowNumConfirm:false,
				consistent:false,
				isShowPasword: false,
				inputFocus: false,
				old_password:false,
				passwordType:"password",
				passwordTypeValue:"",
				password:'',
				new_password:'',
				new_password_confirm:'',
			};
		},
		methods: {
			onFocus(e,formKey){
			 
			},
			onBlur(e,formKey){
				if(!formKey){return false}
				// let  formSo= this.formConter[formKey];
				// let formValue= this.fonData[formKey];
				// if(!formSo){
				// 	formValue.value=formValue.placeholder 
				// }
			},
			showHide(e){
				this.passwordType=this.passwordType=='password'?'text':'password'
			},
			onInput(e,formKey){
				this.password=e;
				if(!validatePassword(e)){
					this.passwordTypeValue='6-16 dígitos, inglês/números/símbolos';
				}else{
					this.passwordTypeValue='';
				}
			},
			isFun(e) {
				this.isFocus = e;
				// 6-16 dígitos, inglês/números/símbolos
			},
			withdrawPassword(){
					if(this.new_password_confirm.length!=6){
						return false;
					}
					if(this.new_password.length!=6){
						return false;
					}
					withdrawPassword({
						type:this.userInfo.set_withdraw_password?1:'0',
						withdraw_password:this.new_password,
						password:this.password,
						withdraw_password_confirm:this.new_password_confirm
					}).then(({msg,code})=>{
						if(code===0){
							Toast.success({
								message:msg,
								duration:1000
							});
							// this.userInfo.set_withdraw_password
							if(this.userInfo.set_withdraw_password){
								if(!this.old_password){
									this.old_password=true;
								}else{
									this.$router.go(-1);
								}
								 // 
								return false;
							}
							this.$store.commit('userInfo',{
								...this.userInfo,
								set_withdraw_password:1
							})
			  
							let tim=setTimeout(()=>{
								clearTimeout(tim)
								// @click=""
								this.$router.replace('/withdraw',{current:2});
								// this.openUrl('withdraw',{current:2})
								// this.$router.go(-1);
								// withdraw?current=3
							},1000)
						}else{
							Toast(msg)
						}
					})
			},
			areSixDigitsTheSame(str) {
			  const regex = /(\d)\1{5}/;
			  return regex.test(str);
			},
			newPassword(e){
				this.isShowPasword=this.areSixDigitsTheSame(e)
				this.new_password=e;
			},
			new_passwordConfirm(e){
				this.isShowNumConfirm=this.areSixDigitsTheSame(e);
				if(e.length==6&& this.new_password!=e){
					this.consistent=true;
				}else{
					this.consistent=false;
				}
				this.new_password_confirm=e;
			},
			onClickLeft() {
				this.$router.go(-1);
			}
		},
	}
</script>

<style scoped>
	.ant-form-explain {
		color: var(--theme-secondary-color-error);
		font-size: .18rem;
		transition: color .3s cubic-bezier(.215, .61, .355, 1);
		line-height: 1.5 !important;
		position: relative;
		padding-left: .3rem;
		margin-bottom: .2rem;
	}
	
	.ant-form-explain::before {
		background-color: var(--theme-secondary-color-error);
		content: "";
		height: 0.18rem;
		margin-right: 0.1rem;
		visibility: visible;
		left: 0;
		top: 50%;
		margin-top: -0.09rem;
		width: 0.18rem;
		display: inline-block;
		position: absolute;
		background-image: url('./../../assets/5e8fdeaf-a95f-4566-adac-d84c9605a176.png');
		background-size: 13.068rem 12.753rem;
		border-radius: 50%;
		background-position: -12.888rem -3.825rem;
	}
	.I9T4OGduED94X6ahO3Qj {
		padding: .2rem;
		padding-top: .4rem;
	}

	.DRcvr_ui3dMkJZpnft5Z {
		color: var(--theme-secondary-color-success);
		margin-bottom: .3rem;
		line-height: 1.5;
		text-align: center;
		font-size: .22rem;

	}

	.Xq4eL9kY4HGkgyNzZTx4 {
		font-size: .24rem;
		margin-bottom: .4rem;
		color: var(--theme-text-color-darken);
		margin-top: 0;
	}

	.Ou1aviPriFX421w97dGA {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: .22rem;
		color: var(--theme-text-color-darken);
		height: .32rem;
		margin-bottom: .15rem;
	}

	.Ou1aviPriFX421w97dGA i {
		color: var(--theme-color-line);
	}

	.Ou1aviPriFX421w97dGA i.isShowNum {
		color: var(--theme-primary-color);
	}

	.FoD6bH3V5A47RnLkco6A {
		font-size: .22rem;
		margin-bottom: .5rem;
		color: var(--theme-secondary-color-finance);
		line-height: 1.75;
	}

	.conterArr {
		position: relative;
	}

	.conterArr .ant-form-explain {
		color: var(--theme-secondary-color-error);
		font-size: .18rem;
		position: absolute;
		height: .4rem;
		margin-bottom: -.4rem;
		bottom: -.1rem;
	}

	.conterArr .ant-form-explain::before {
		background-color: var(--theme-secondary-color-error);
		content: "";
		height: 0.18rem;
		margin-right: 0.1rem;
		visibility: visible;
		width: 0.18rem;
		display: inline-block;
		background-image: url('./../../assets/5e8fdeaf-a95f-4566-adac-d84c9605a176.png');
		background-size: 13.068rem 12.753rem;
		border-radius: 50%;
		background-position: -12.888rem -3.825rem;
	}
	.verification-btn__group {
	    -ms-flex-pack: center;
	    background-color: var(--theme-main-bg-color);
	    bottom: 0;
	    -webkit-box-shadow: 0 -.03rem .1rem 0 rgba(0,0,0,.1);
	    box-shadow: 0 -.03rem .1rem 0 rgba(0,0,0,.1);
	    display: -ms-flexbox;
	    display: flex;
	    height: 1.3rem;
	    justify-content: center;
	    padding-top: .2rem;
	    position: fixed;
		left: auto; 
	    /* width: var(--theme-max-width) */
		width: 100%;
		max-width:var(--theme-max-width) ;
	}
.Xhr_XJt3jLTtAL8pC16D button {
    font-size: .24rem;
    height: .7rem;
	 width: 7.1rem!important;
	  background-color: var(--theme-primary-color);
	     border-color: var(--theme-primary-color)
}

</style>
